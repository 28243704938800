<script setup lang="ts">
import ColorPicker from "primevue/colorpicker";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import ToggleButton from "primevue/togglebutton";
import { computed, ref, type PropType, watch } from "vue";

import type { AdFormatSchemaValue } from "@/types/schema-types";
import { isHexColor, formatColor } from "@/utils/color";

const emit = defineEmits<{
  (e: "update:model-value", value: string): void;
}>();

const innerValue = ref<string>("");
const showColorPicker = ref<boolean>(false);
const props = defineProps({
  schemaValues: {
    type: Array as PropType<AdFormatSchemaValue[]>,
    default: () => [],
  },
  name: {
    type: String,
    default: undefined,
  },
  modelValue: {
    type: String,
    default: "",
  },
  error: {
    type: Boolean,
    default: false,
  },
});

watch(
  () => props.modelValue,
  () => {
    innerValue.value = props.modelValue;
  },
  { immediate: true },
);

watch(innerValue, () => {
  emit("update:model-value", innerValue.value);
});

const schemaValue = computed(() => props.schemaValues.find((v) => v.name === props.name));
const isBoolean = computed(() => schemaValue.value?.type === "boolean");
const isEnum = computed(() => schemaValue.value?.type === "enum");
const isNumeric = computed(() => schemaValue.value?.type === "numeric");
const isColor = computed(() => isHexColor(schemaValue.value?.default.toString() ?? innerValue.value));
const isForcedColor = computed(() => showColorPicker.value);
const enumOptions = computed(() =>
  isEnum.value ? schemaValue.value?.enum?.map((it, i) => ({ label: it, value: i.toString() })) ?? [] : [],
);
const classes = computed(() => ({
  "p-invalid": props.error,
}));
</script>
<template>
  <InputSwitch
    v-if="isBoolean"
    :model-value="innerValue.toLowerCase() === 'true' || innerValue === '1'"
    @update:model-value="emit('update:model-value', $event)"
  />
  <Dropdown
    v-else-if="isEnum"
    v-model="innerValue"
    :options="enumOptions"
    option-label="label"
    option-value="value"
    :class="classes"
  />
  <InputNumber
    v-else-if="isNumeric"
    :model-value="parseInt(innerValue, 10)"
    @update:model-value="emit('update:model-value', String($event))"
  />
  <template v-else>
    <ColorPicker
      v-if="isColor || isForcedColor"
      :model-value="innerValue"
      :pt="{
        input: { style: { height: '2.8rem', width: '2.8rem' } },
      }"
      class="mr-1"
      @update:model-value="emit('update:model-value', formatColor($event))"
    />
    <InputText v-model="innerValue" class="ml-1 mr-3" />
    <ToggleButton
      v-if="!isColor"
      v-model="showColorPicker"
      on-label=""
      off-label=""
      on-icon="pi pi-palette"
      off-icon="pi pi-palette"
      input-id="showColorPicker"
    />
  </template>
</template>
@/utils/color
