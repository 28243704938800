import { ref, type Ref } from "vue";

export interface Message {
  severity: "success" | "info" | "warn" | "error";
  title?: string;
  detail?: string;
  life?: number;
  closable?: boolean;
}

const messenger = ref<Message | null>(null);

interface UseNotification {
  messenger: Ref<Message | null>;
  notify: (message: Message) => void;
  notifyWarning: (message: Omit<Message, "severity">) => void;
  notifySuccess: (message: Omit<Message, "severity">) => void;
  notifyError: (message: Omit<Message, "severity">) => void;
  notifyInfo: (message: Omit<Message, "severity">) => void;
}

export default function useNotification(): UseNotification {
  const notify = (message: Message): void => {
    messenger.value = { ...message };
  };

  const notifyError = (message: Omit<Message, "severity">): void => {
    messenger.value = { ...message, severity: "error" };
  };

  const notifyWarning = (message: Omit<Message, "severity">): void => {
    messenger.value = { ...message, severity: "warn" };
  };

  const notifySuccess = (message: Omit<Message, "severity">): void => {
    messenger.value = { ...message, severity: "success" };
  };

  const notifyInfo = (message: Omit<Message, "severity">): void => {
    messenger.value = { ...message, severity: "info" };
  };

  return {
    messenger,
    notify,
    notifyWarning,
    notifySuccess,
    notifyError,
    notifyInfo,
  };
}
