<script setup lang="ts">
import Button from "primevue/button";
import { computed, type PropType } from "vue";

import useConfirmDialog from "@/composables/use-confirm-messages";
import useNotification from "@/composables/use-notifications";
import { deleteAdLayout } from "@/services/ad-layouts-services";
import type { AdLayout } from "@/types/layout-types";
import type { AdFormatSchema } from "@/types/schema-types";
import { NOTIFICATION_DURATION } from "@/utils/common";

const { confirmDanger } = useConfirmDialog();
const { notifySuccess, notifyError } = useNotification();

const props = defineProps({
  adLayout: {
    type: Object as PropType<AdLayout>,
    default: null,
  },
  schema: {
    type: Object as PropType<AdFormatSchema>,
    default: null,
  },
  openForm: {
    type: Function,
    default: null,
  },
  refetch: {
    type: Function,
    default: null,
  },
  lastSchema: {
    type: Object as PropType<AdFormatSchema>,
    default: null,
  },
  layoutUsed: {
    type: Boolean,
    default: false,
  },
});

const isLastSchema = computed(() => props.schema.version === props.lastSchema.version);

const buttons = computed(() => [
  {
    label: "Edit",
    icon: "pi pi-file-edit",
    command: () => props.openForm(props.schema, props.adLayout),
  },
  {
    label: "Duplicate",
    icon: "pi pi-clone",
    command: () =>
      props.openForm(props.schema, { ...props.adLayout, id: undefined, name: `${props.adLayout.name}-copy` }),
  },
  {
    label: "Duplicate to last version",
    icon: "pi pi-copy",
    disabled: isLastSchema.value,
    command: () =>
      props.openForm(props.lastSchema, {
        ...props.adLayout,
        id: undefined,
        name: `${props.adLayout.name}`,
        version: props.lastSchema.version,
      }),
  },
  {
    label: "Delete",
    icon: "pi pi-trash",
    disabled: props.layoutUsed,
    severity: "danger",
    command: async () => {
      if (!(await confirmDanger(`Are you sure you want to delete the ad layout: ${props.adLayout.name}?`))) {
        return;
      }

      try {
        await deleteAdLayout(props.adLayout.id);
        notifySuccess({
          detail: `The ad layout ${props.adLayout.name} was successfully deleted`,
          life: NOTIFICATION_DURATION,
        });
        props.refetch();
      } catch (err) {
        notifyError({ detail: `The ad layout deletion failed. ${err}` });
      }
    },
  },
]);
</script>
<template>
  <div class="flex justify-content-end">
    <div v-for="button in buttons.filter((x) => !x.disabled)" :key="button.label">
      <Button
        v-tooltip.bottom="button.label"
        type="button"
        :icon="button.icon"
        :aria-label="button.label"
        :severity="button.severity || 'primary'"
        text
        rounded
        @click="button.command"
      />
    </div>
  </div>
</template>
