<script setup lang="ts">
import Button from "primevue/button";
import { StyleValue, ComputedRef, PropType, computed, ref } from "vue";

import AppStoreIcon from "@/assets/icons/app-store.svg";
import useAuthentication from "@/composables/use-authentication";
import { AppInformation } from "@/types/preview";
import { AdFormatSchema } from "@/types/schema-types";
import { getValue } from "@/utils/ad-layout";

const { user } = useAuthentication();
const props = defineProps({
  configuration: {
    type: Object as PropType<Array<{ key: string; value: string }>>,
    default: null,
  },
  schema: {
    type: Object as PropType<AdFormatSchema>,
    default: null,
  },
  appInformation: {
    type: Object as PropType<AppInformation>,
    default: null,
  },
  forceDisplay: {
    type: Boolean,
    default: true,
  },
  currentTime: {
    type: Number,
    default: 0,
  },
  isVideo: {
    type: Boolean,
    default: true,
  },
});

const getValueFromConfig = (confKey: string, defaultValue: string) =>
  getValue(
    props.configuration?.find(({ key }) => key === confKey),
    props.schema,
    defaultValue,
  );

const forceClose = ref(false);
const installView = ref(false);

const position = computed(() => getValueFromConfig("skp", "BOTTOM"));
const closeEnabled = computed(() => getValueFromConfig("skd", "STATIC_ON_SCREEN") === "DISMISSED");
const autoDismissDelay = computed(() =>
  Number.parseInt(props.configuration?.find(({ key }) => key === "sksdd")?.value ?? "0", 10),
);
const videoDelay = computed(() =>
  Number.parseInt(props.configuration?.find(({ key }) => key === "skvd")?.value ?? "0", 10),
);
const companionDelay = computed(() =>
  Number.parseInt(props.configuration?.find(({ key }) => key === "skcd")?.value ?? "0", 10),
);
const showDelay = computed(() => (props.isVideo ? videoDelay.value : companionDelay.value));

const style: ComputedRef<StyleValue> = computed(() => ({
  position: "absolute",
  bottom: position.value === "BOTTOM" ? "10px" : "20px",
  left: 0,
  right: 0,
  marginLeft: "auto",
  marginRight: "auto",
  width: "80%",
  height: "12%",
  backgroundColor: "#ECEFF0",
  borderRadius: "15px",
  zIndex: 10,
  padding: "10px",
}));
const iconStyle: StyleValue = {
  height: "60px",
  borderRadius: "15px",
};
const appStoreStyle: StyleValue = {
  height: "13px",
  filter: "grayscale(1)",
};
const closeButtonStyle: StyleValue = {
  position: "absolute",
  top: "5px",
  right: "12px",
  fontSize: "0.8rem",
  color: "#909090",
  cursor: "pointer",
};

const resetSkan = () => {
  forceClose.value = false;
};

defineExpose({
  resetSkan,
});

const show = computed(
  () =>
    !forceClose.value &&
    !installView.value &&
    (props.forceDisplay ||
      (showDelay.value <= props.currentTime &&
        (autoDismissDelay.value === 0 || props.currentTime <= autoDismissDelay.value))),
);
const showInstallView = computed(() => !forceClose.value && installView.value);
</script>
<template>
  <div v-if="show" :style="style">
    <i
      v-if="closeEnabled"
      class="pi pi-times"
      :style="closeButtonStyle"
      @click="() => (forceClose = true)"
      @keypress="() => {}"
    />
    <div class="flex gap-2 align-items-center justify-content-between">
      <img :src="appInformation?.icon" alt="App Icon" :style="iconStyle" />
      <div class="flex flex-column flex-grow-1 gap-1" style="white-space: nowrap; overflow: hidden">
        <div class="flex align-items-center gap-1">
          <img :src="AppStoreIcon" alt="App store icon" :style="appStoreStyle" />
          <span style="color: #8c8e91; font-size: small">App Store</span>
        </div>
        <span>
          <strong>{{ appInformation?.name }}</strong>
        </span>
        <span style="color: #8c8e91; overflow: hidden; text-overflow: ellipsis">
          {{ appInformation?.legend }}
        </span>
      </div>
      <div>
        <Button label="Install" severity="secondary" rounded size="small" @click="() => (installView = true)" />
      </div>
    </div>
  </div>
  <div>
    <div
      :style="{
        width: 'calc(100% - 16px)',
        height: showInstallView ? 'calc(100% - 16px)' : '0%',
        background: showInstallView ? 'rgba(0,0,0,0.75)' : 'rgba(0,0,0,0)',
        borderRadius: '10px',
        position: 'absolute',
        top: '8px',
        left: '8px',
        zIndex: '11',
        overflow: 'hidden',
        transition: 'background 0.5s linear',
      }"
    >
      <div
        :style="{
          width: '100%',
          height: showInstallView ? '40%' : '0',
          background: '#EAECED',
          position: 'absolute',
          bottom: '0',
          borderTopLeftRadius: '15px',
          borderTopRightRadius: '15px',
          padding: '20px',
          transition: 'all 0.25s linear',
        }"
      >
        <div class="flex flex-column gap-4">
          <div class="flex flex-grow-1 justify-content-between align-items-center">
            <span style="font-size: x-large; color: #0e0d0e">App Store</span>
            <div
              style="
                background-color: #dddddf;
                border-radius: 50%;
                padding: 8px;
                height: 34px;
                width: 34px;
                cursor: pointer;
              "
              @click="
                () => {
                  installView = false;
                }
              "
              @keypress="() => {}"
            >
              <i class="pi pi-times" style="font-size: large; color: #7c7c81; font-weight: 500" />
            </div>
          </div>
          <div class="flex-grow-1" style="background: #ffffff; padding: 8px; border-radius: 15px">
            <div class="flex gap-2 align-items-center justify-content-between mb-3">
              <img :src="appInformation?.icon" alt="App Icon" :style="iconStyle" />
              <div class="flex flex-column flex-grow-1 gap-1" style="white-space: nowrap; overflow: hidden">
                <span>
                  <strong>{{ appInformation?.name }}</strong>
                </span>
                <span style="color: #8c8e91; overflow: hidden; text-overflow: ellipsis">
                  {{ appInformation?.publisher }}
                </span>
                <span style="color: #8c8e91; overflow: hidden; text-overflow: ellipsis">
                  {{ appInformation?.legend }}
                </span>
              </div>
            </div>
            <div class="mb-3 ml-2" style="background: #dbdbdc; height: 1px; width: 100%"></div>
            <span class="block mb-2 ml-2" style="color: #8c8e91; font-size: small">
              Account: {{ user?.email || "mock@voodoo.io" }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
