<script setup lang="ts">
import { useQuery, useQueryClient } from "@tanstack/vue-query";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import { v4 as uuidv4 } from "uuid";
import { computed, ref } from "vue";

import { listAndroidMocks, listIosMocks, getMock } from "@/services/test-app-services";
import { type Mock, type MockContent } from "@/types/test-app-types";
import { generateQRCode } from "@/utils/qr-code";

import MockFileForm from "./components/MockFileForm.vue";
import MockFilePanel from "./components/MockFilePanel.vue";

useQueryClient();
const { data: androidData, refetch: refetchAndroid } = useQuery({
  queryKey: ["androidMockList"],
  queryFn: listAndroidMocks,
  staleTime: Infinity,
});

const { data: iosData, refetch: refetchIos } = useQuery({
  queryKey: ["iosMockList"],
  queryFn: listIosMocks,
  staleTime: Infinity,
});

const editMock = ref<Mock | undefined>(undefined);
const isFormVisible = ref(false);
const isEdition = ref(false);
const isModalVisible = ref(false);
const selectedMock = ref<Mock | null>(null);
const qrCode = ref("");

const androidMocks = computed(() => [...(androidData.value || [])].sort((a, b) => a.id.localeCompare(b.id)));
const iosMocks = computed(() => [...(iosData.value || [])].sort((a, b) => a.id.localeCompare(b.id)));

const listOfMocks = computed(() => ({
  android: androidMocks.value.map(({ id }) => id),
  ios: iosMocks.value.map(({ id }) => id),
}));

const openForm = async (os?: string, filename?: string, duplicate?: boolean): Promise<void> => {
  if (!os || !filename) {
    editMock.value = undefined;
    isFormVisible.value = true;
    isEdition.value = false;
    return;
  }
  const content = await getMock(os, filename);
  editMock.value = {
    os,
    filename: duplicate ? `${filename}_copy` : filename,
    content: duplicate ? { ...content, id: uuidv4() } : content,
  };
  isEdition.value = !duplicate;
  isFormVisible.value = true;
};

const closeForm = (): void => {
  refetchAndroid();
  refetchIos();
  isFormVisible.value = false;
  editMock.value = undefined;
};

const openModal = async (os: string, filename: string): Promise<void> => {
  const mocks = os === "android" ? androidMocks.value : iosMocks.value;
  const { url } = mocks.find((mock) => mock.id === filename) || {};
  const [qrCodeGen, content] = await Promise.all([generateQRCode(encodeURI(url || "")), getMock(os, filename)]);
  qrCode.value = qrCodeGen || "";
  selectedMock.value = { os, filename, content };
  isModalVisible.value = true;
};

const extractContentFromMock = (content: object) => {
  const isArray = Array.isArray(content);
  const mock = content as MockContent;
  return !isArray
    ? [`${mock.adType || "unknown type"} - ${mock.name || "unknown name"}`]
    : content.map((it) => `${it.adType || "unknown type"} - ${it.name || "unknown name"}`);
};
</script>

<template>
  <div class="flex justify-content-between align-items-center">
    <h1>TestApp - Mock creatives</h1>
    <Button label="Create a new mock file" icon="pi pi-plus" @click="openForm()" />
  </div>
  <div class="flex justify-content-between">
    <MockFilePanel
      :mocks="androidMocks"
      os="android"
      :open-form="openForm"
      :open-modal="openModal"
      :refetch="refetchAndroid"
    />
    <MockFilePanel :mocks="iosMocks" os="ios" :open-form="openForm" :open-modal="openModal" :refetch="refetchIos" />
  </div>

  <Dialog
    v-model:visible="isFormVisible"
    maximizable
    modal
    :header="isEdition ? 'Edit mock file' : 'Create mock file'"
    :style="{ width: '75vw' }"
  >
    <MockFileForm
      :edit-mock="editMock"
      :existing-mocks="listOfMocks"
      :close-modal="closeForm"
      :is-edition="isEdition"
    />
  </Dialog>

  <Dialog
    v-model:visible="isModalVisible"
    dismissable-mask
    maximizable
    modal
    :header="`QR Code of ${selectedMock?.filename} [${selectedMock?.os}]`"
    :style="{ width: '75vw' }"
  >
    <div class="flex flex-column justify-content-center align-items-center gap-4">
      <div>
        <strong>Mock content:</strong>
        {{ extractContentFromMock(selectedMock?.content || {}).join(", ") }}
      </div>
      <img :src="qrCode" alt="Mock Qr Code" />
      <Button
        icon="pi pi-refresh"
        label="Refresh QR Codes"
        text
        @click="
          async () => {
            selectedMock?.os === 'android' ? await refetchAndroid() : await refetchIos();
            openModal(selectedMock?.os || '', selectedMock?.filename || '');
          }
        "
      />
    </div>
  </Dialog>
</template>
