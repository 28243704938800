<script setup lang="ts">
import Button from "primevue/button";
import { ComputedRef, computed, StyleValue, PropType } from "vue";

import { AppInformation } from "@/types/preview";

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  closeAction: {
    type: Function as PropType<() => void>,
    default: null,
  },
  appInformation: {
    type: Object as PropType<AppInformation>,
    default: null,
  },
  isAndroid: {
    type: Boolean,
    default: false,
  },
});

const containerStyle: ComputedRef<StyleValue> = computed(() => ({
  width: "calc(100% - 16px)",
  height: props.show ? "calc(100% - 16px)" : "0%",
  background: "rgba(0,0,0,0.75)",
  borderRadius: "10px",
  position: "absolute",
  top: "8px",
  left: "8px",
  zIndex: "11",
  overflow: "hidden",
  transition: "background 0.5s linear",
}));
const iconStyle: StyleValue = {
  height: "120px",
  borderRadius: "15px",
};
const severity = computed(() => (props.isAndroid ? "success" : "primary"));
</script>

<template>
  <div :style="containerStyle">
    <div
      :style="{
        width: '100%',
        height: props.show ? '95%' : '0',
        background: '#FFF',
        position: 'absolute',
        bottom: '0',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        padding: '20px',
        transition: 'all 0.25s linear',
      }"
    >
      <div class="flex flex-column gap-4">
        <div class="flex-grow-1">
          <Button
            text
            :severity="severity"
            @click="
              () => {
                closeAction();
              }
            "
          >
            <i class="pi pi-arrow-left mr-2" />
            Back
          </Button>
        </div>
        <div class="flex-grow-1">
          <div class="flex gap-2 justify-content-between mb-3">
            <img :src="appInformation?.icon" alt="App Icon" :style="iconStyle" />
            <div class="flex flex-column justif-content-around flex-grow-1">
              <div class="flex flex-column flex-grow-1 gap-1" style="white-space: nowrap; overflow: hidden">
                <span>
                  <strong>{{ appInformation?.name }}</strong>
                </span>
                <span style="color: #8c8e91; overflow: hidden; text-overflow: ellipsis">
                  {{ appInformation?.publisher }}
                </span>
                <span style="color: #8c8e91; overflow: hidden; text-overflow: ellipsis">
                  {{ appInformation?.legend }}
                </span>
              </div>
              <div>
                <Button rounded size="small" :severity="severity">Install</Button>
              </div>
            </div>
          </div>
          <div class="mb-3 ml-2" style="background: #dbdbdc; height: 1px; width: 100%"></div>
        </div>
      </div>
    </div>
  </div>
</template>
