import axios, { type AxiosInstance } from "axios";

import useAuthentication from "@/composables/use-authentication";
import { config } from "@/config";

const { accessToken } = useAuthentication();

export const getMissionControlApi: (endpoint: string) => AxiosInstance = (endpoint) =>
  axios.create({
    baseURL: `${config.missionControlV2Url}/${endpoint}`,
    headers: { Authorization: `Bearer ${accessToken.value}` },
  });
