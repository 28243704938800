import {
  createRouter,
  createWebHistory,
  type NavigationGuardNext,
  type RouteLocationNormalized,
  type RouterOptions,
} from "vue-router";

import useAuthentication from "@/composables/use-authentication";
import NotFoundPage from "@/pages/NotFoundPage.vue";
import RootPage from "@/pages/RootPage.vue";

import { abtestsRoutes } from "./abtests";
import { adlayoutsRoutes } from "./adlayouts";
import { authRoutes } from "./auth";
import links from "./links";
import { pinnedRoutes } from "./pinned";
import { schemasRoutes } from "./schemas";
import { testAppRoutes } from "./testapp";

const routerConfig: RouterOptions = {
  history: createWebHistory("/"),
  routes: [
    ...authRoutes,
    {
      path: "/",
      component: RootPage,
      props: true,
      children: [...abtestsRoutes, ...adlayoutsRoutes, ...pinnedRoutes, ...schemasRoutes, ...testAppRoutes],
    },
    {
      path: "",
      redirect: { name: links.adlayouts },
    },
    {
      path: "/implicit/callback",
      name: links.loginRedirect,
      component: NotFoundPage,
    },
    {
      path: "/:pathMatch(.*)*",
      name: "not-found",
      component: NotFoundPage,
      meta: { noAuthRequired: true },
    },
  ],
};

export const router = createRouter(routerConfig);

let needWindowReferesh = false;

router.beforeEach((to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (needWindowReferesh === true) {
    window.location.replace(to.fullPath);
  }
  return next();
});

router.beforeEach((to: RouteLocationNormalized, _, next: NavigationGuardNext) => {
  if (to.name === links.loginRedirect) {
    if (
      to.query.error === "unauthorized" &&
      to.query.error_description === "Please verify your email before logging in."
    ) {
      return next({ name: links.login });
    }
    needWindowReferesh = true;
  }
  return next();
});

router.beforeEach((to: RouteLocationNormalized) => {
  const { routeAuthGuard } = useAuthentication();
  return routeAuthGuard({
    router,
    loginRouteName: links.login,
    forbiddenRouteName: links.forbidden,
    authRedirectRouteName: links.loginRedirect,
  })(to);
});

router.beforeEach((to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) => {
  document.title = `ADN Backoffice ${(to?.meta?.title && ` | ${to.meta.title}`) || ""}`;
  return next();
});
