<script setup lang="ts">
import Dropdown from "primevue/dropdown";
import { computed } from "vue";

import { formats } from "@/types/common-types";

const props = defineProps({
  includeAll: {
    type: Boolean,
    default: false,
  },
});

const options = computed(() => [props.includeAll && "ALL", ...formats].filter((x) => x));
</script>
<template>
  <Dropdown :options="options" />
</template>
