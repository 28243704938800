import DeliverItIcon from "@/assets/icons/deliver-it-3d.webp";
import MobControlIcon from "@/assets/icons/mob-control.webp";
import PaperIo2Icon from "@/assets/icons/paper-io.webp";
import SlapNRunIcon from "@/assets/icons/slap-and-run.webp";
import WoodturningIcon from "@/assets/icons/woodturning.webp";
import { PreviewCreative } from "@/types/preview";

export const getPositionFromPlacement = (
  placement: string,
): { top?: string; right?: string; bottom?: string; left?: string } => {
  const offset = "14px";

  switch (placement) {
    case "TOP_RIGHT":
      return {
        top: offset,
        right: offset,
      };
    case "BOTTOM_LEFT":
      return {
        bottom: offset,
        left: offset,
      };
    case "BOTTOM_RIGHT":
      return {
        bottom: offset,
        right: offset,
      };
    default:
      return {
        top: offset,
        left: offset,
      };
  }
};

export const getButtonSize = (size: string): string => (size?.toLowerCase() === "small" ? "20px" : "24px" ?? "20px");

export const getCloseButtonSize = (size: string): string =>
  size?.toLowerCase() === "small" ? "16px" : "20px" ?? "16px";

export const getFontSize = (size: string): string => (size?.toLowerCase() === "small" ? "12px" : "14px" ?? "12px");

export const creatives: PreviewCreative[] = [
  {
    label: "Slap And Run",
    videoUrl: "https://dfxieqnfwzpl3.cloudfront.net/org_Hhxfyktf76CRLwuW_DFLT_856e8f77-3819-4f27-bf97-6ac143155946.mp4",
    playableUrl:
      "https://dfxieqnfwzpl3.cloudfront.net/org_Hhxfyktf76CRLwuW_DFLT_d1535ee7-3bda-4456-ae6f-4e3619edbc59.html",
    appInformation: {
      name: "Slap And Run",
      publisher: "Voodoo",
      icon: SlapNRunIcon,
      legend: "Prank'em all!",
    },
  },
  {
    label: "Deliver it 3D",
    videoUrl: "https://dfxieqnfwzpl3.cloudfront.net/org_K2H1GVDI1i6bpuTb_DFLT_ce3da9d4-56ad-428b-a762-ea454a5cf478.mp4",
    playableUrl:
      "https://dfxieqnfwzpl3.cloudfront.net/org_K2H1GVDI1i6bpuTb_DFLT_c2e920b7-9e57-4ee5-8760-3745c4da5ff2.html",
    appInformation: {
      name: "Deliver It 3D",
      publisher: "Voodoo",
      icon: DeliverItIcon,
      legend: "Have you ever wanted to deliver packages?",
    },
  },
  {
    label: "Mob Control",
    videoUrl: "https://dfxieqnfwzpl3.cloudfront.net/org_K2H1GVDI1i6bpuTb_DFLT_3e9857da-50a1-4c60-8ed6-c96538aaa630.mp4",
    playableUrl:
      "https://dfxieqnfwzpl3.cloudfront.net/org_K2H1GVDI1i6bpuTb_DFLT_feb95c2b-3a2c-48d8-9068-66c398b15824.html",
    appInformation: {
      name: "Mob Control",
      publisher: "Voodoo",
      icon: MobControlIcon,
      legend: "Multiply your Crowd!",
    },
  },
  {
    label: "Paper.io2",
    videoUrl: "https://dfxieqnfwzpl3.cloudfront.net/org_Hhxfyktf76CRLwuW_DFLT_5e23aa85-a05a-4104-bea9-1084f805f19d.mp4",
    playableUrl:
      "https://dfxieqnfwzpl3.cloudfront.net/org_Hhxfyktf76CRLwuW_DFLT_533e0b95-02fa-47a7-83cb-eb7577faa8db.html",
    appInformation: {
      name: "Paper.io 2",
      publisher: "Voodoo",
      icon: PaperIo2Icon,
      legend: "A smooth drawing experience!",
    },
  },
  {
    label: "Woodturning",
    videoUrl:
      "https://d1vshcplir4n4d.cloudfront.net/org_K2H1GVDI1i6bpuTb_DFLT_398f2c3a-9d53-4fee-843a-c0d94f69546f.mp4",
    playableUrl:
      "https://dfxieqnfwzpl3.cloudfront.net/org_Hhxfyktf76CRLwuW_DFLT_1b53c19a-6d91-45a7-b35c-e9e3a69b9335.html",
    appInformation: {
      name: "Woodturning 3D",
      publisher: "Voodoo",
      icon: WoodturningIcon,
      legend: "The best woodturning simulator.",
    },
  },
];
