import type { MenuItem } from "primevue/menuitem";
import { reactive } from "vue";

import useAuthentication from "@/composables/use-authentication";

import links from "./links";

const { logout } = useAuthentication();

export const menu = reactive<MenuItem[]>([
  {
    label: "Ad Layouts",
    to: links.adlayouts,
  },
  {
    label: "A/B Tests",
    to: links.abtests,
  },
  {
    label: "Pinned configurations",
    to: links.pinned,
  },
  {
    label: "Schemas",
    to: links.schemas,
  },
  {
    label: "Test App",
    to: links.testapp,
  },
]);

export const userMenu = reactive<MenuItem[]>([{ label: "Logout", icon: "pi pi-sign-out", command: logout }]);
