<script setup lang="ts">
import Button from "primevue/button";
import { useRoute } from "vue-router";

import { AppHeader } from "@/components/business";
import useAuthentication from "@/composables/use-authentication";

import RocketImg from "./assets/rocket.png";

const route = useRoute();

const { login } = useAuthentication();

const onLogin = () => {
  login(route.query?.next?.toString());
};

/** If `auth0=true` is passed as a query, then redirect directly to auth0.
 * This is used by auth0 to allow user invitations to an organization for instance.
 */
if (route.query.auth0 === "true") {
  const invitation = route.query?.invitation as string | undefined;
  const organization = route.query?.organization as string | undefined;
  login(route.query?.next?.toString(), {
    invitation,
    organization,
  });
}
</script>

<template>
  <div class="login-page">
    <AppHeader>
      <template #end>
        <Button label="Log In" class="p-button-outlined p-button-secondary mr-3" @click="onLogin" />
      </template>
    </AppHeader>

    <div class="login-section">
      <div class="login-content">
        <div class="login-content_text">
          <p class="login-content_text-header">Ad Experience Backoffice</p>
          <p class="login-content_text-subheader">
            <br />
          </p>
        </div>
        <img :src="RocketImg" alt="Rocket" height="500" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.login-page {
  background: linear-gradient(180deg, #e7f3ff 0%, rgba(231, 243, 255, 0) 100%);
  width: 100%;
}
.login-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-content_text-header {
  font-weight: 900;
  font-size: 48px;
  line-height: 57px;
  color: var(--adn-primary-dark);
}
.login-content_text-subheader {
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.04em;
  color: var(--adn-primary-dark);
}
.need-help-block {
  background-color: var(--adn-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38rem;
  flex-direction: column;
}
.need-help-block_text {
  color: white;
  font-size: 48px;
  line-height: 57px;
  font-weight: 800;
}
</style>
