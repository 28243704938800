import type { RouteRecordRaw } from "vue-router";

import DummyRouterViewVue from "@/components/DummyRouterView.vue";
import SchemasListPage from "@/pages/schemas/SchemasListPage.vue";

import links from "./links";

export const schemasRoutes: RouteRecordRaw[] = [
  {
    path: links.schemas,
    component: DummyRouterViewVue,
    children: [
      {
        path: "",
        component: SchemasListPage,
        name: links.schemas,
        props: true,
        meta: { title: "Schemas" },
      },
    ],
  },
];
