<script setup lang="ts">
import Tag from "primevue/tag";
import { PropType, computed } from "vue";

import { AbTest } from "@/types/ab-test-types";
import { PinnedConfiguration } from "@/types/pinned-types";
import { getAbTestsWithLayout, getPinnedConfigurationsWithLayout } from "@/utils/ad-layout";

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  abTests: {
    type: Array as PropType<AbTest[]>,
    default: () => [],
  },
  pinnedConfigurations: {
    type: Array as PropType<PinnedConfiguration[]>,
    default: () => [],
  },
});

const abTestsWithLayout = computed(() => getAbTestsWithLayout(props.id, props.abTests));
const pinnedConfigurations = computed(() => getPinnedConfigurationsWithLayout(props.id, props.pinnedConfigurations));

const androidAbTests = computed(() =>
  abTestsWithLayout.value.filter((it) => it.platform.toLocaleLowerCase() === "android").map((it) => it.name),
);
const iosAbTests = computed(() =>
  abTestsWithLayout.value.filter((it) => it.platform.toLocaleLowerCase() === "ios").map((it) => it.name),
);

const androidPinned = computed(() =>
  pinnedConfigurations.value
    .filter((it) => it.platform.toLocaleLowerCase() === "android")
    .map((it) => `${it.format}:${it.placement}`),
);

const iosPinned = computed(() =>
  pinnedConfigurations.value
    .filter((it) => it.platform.toLocaleLowerCase() === "ios")
    .map((it) => `${it.format}:${it.placement}`),
);
</script>

<template>
  <Tag v-if="androidAbTests.length > 0" icon="pi pi-android" severity="success">
    A/B Tests: {{ androidAbTests.join("; ") }}
  </Tag>
  {{ " " }}
  <Tag v-if="iosAbTests.length > 0" icon="pi pi-apple" severity="info">A/B Tests: {{ iosAbTests.join("; ") }}</Tag>
  {{ " " }}
  <Tag v-if="androidPinned.length > 0" icon="pi pi-android" severity="success">
    Pinned: {{ androidPinned.join("; ") }}
  </Tag>
  {{ " " }}
  <Tag v-if="iosPinned.length > 0" icon="pi pi-apple" severity="info">Pinned: {{ iosPinned.join("; ") }}</Tag>
</template>
