<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  value: {
    type: Number,
    default: 100,
  },
  color: {
    type: String,
    default: "#FFFFFF",
  },
  interval: {
    type: Number,
    default: 1000,
  },
  size: {
    type: String,
    default: "25px",
  },
  shadow: {
    type: String,
    default: "#6F6F6F 0 0 10px",
  },
});

const dashArray = 565;
const dashOffset = computed(() => {
  const percent = Math.min(props.value, 100) / 100;
  return dashArray - dashArray * (1 - percent);
});
</script>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="-25 -25 250 250"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    :style="{
      transform: 'rotate(-90deg)',
      transition: `stroke-dashoffset ${interval}ms linear 0s`,
      filter: `drop-shadow(${props.shadow})`,
    }"
  >
    <circle
      r="90"
      cx="100"
      cy="100"
      :stroke="props.color"
      stroke-width="16px"
      stroke-linecap="round"
      :stroke-dashoffset="`${dashOffset}px`"
      fill="transparent"
      :stroke-dasharray="`${dashArray}px`"
      :style="{ transition: `stroke-dashoffset ${interval}ms linear 0s` }"
    ></circle>
    <text
      x="70px"
      y="117px"
      :fill="props.color"
      font-size="125px"
      font-weight="bold"
      style="transform: rotate(90deg) translate(-2px, -180px)"
    >
      x
    </text>
  </svg>
</template>
