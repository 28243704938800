<script setup lang="ts">
import { type PropType } from "vue";
import { computed } from "vue";

import type { AdFormatSchemaValue } from "@/types/schema-types";
import { isHexColor } from "@/utils/color";

enum ValueType {
  BOOLEAN,
  COLOR,
  ENUM,
  RAW,
}

const props = defineProps({
  name: { type: String, default: null },
  value: { type: String, default: null },
  schemaValues: { type: Array as PropType<AdFormatSchemaValue[]>, default: () => [] },
});

const schemaValue = computed(() => props.schemaValues.find((it) => it.name === props.name));

const valueType = computed(() => {
  if (schemaValue.value?.enum && parseInt(props.value.toString(), 10) >= 0) {
    return ValueType.ENUM;
  }
  if (schemaValue.value?.type.toLowerCase() === "boolean") {
    return ValueType.BOOLEAN;
  }
  return isHexColor(props.value.toString()) ? ValueType.COLOR : ValueType.RAW;
});

const valueFromEnum = computed(() => schemaValue.value?.enum?.at(parseInt(props.value.toString(), 10)));
const intValue = computed(() => parseInt(props.value.toString(), 10));
const valueFromBoolean = computed(() =>
  Number.isInteger(intValue.value) ? intValue.value === 1 : props.value.toString().toLowerCase() === "true",
);
const valueEnumIndexOf = computed(() => {
  if (valueType.value === ValueType.ENUM) {
    return props.value.toString();
  }

  return schemaValue.value?.enum?.indexOf(props.value.toString()).toString() ?? "-1";
});
</script>
<template>
  <template v-if="valueType === ValueType.BOOLEAN">
    <i v-if="valueFromBoolean" class="pi pi-check text-teal-500" />
    <i v-if="!valueFromBoolean" class="pi pi-times text-red-500" />
  </template>
  <template v-else-if="valueType === ValueType.ENUM">
    {{ valueFromEnum }}
    <span class="text-xs text-gray-500">[enum: {{ valueEnumIndexOf }}]</span>
  </template>
  <template v-else-if="valueType === ValueType.COLOR">
    <div class="flex gap-2 align-items-center">
      <span
        :style="{
          width: '2rem',
          height: '1.2rem',
          display: 'inline-block',
          border: '1px solid #ced4da',
          backgroundColor: props.value,
          borderRadius: '6px',
        }"
      ></span>
      {{ props.value }}
    </div>
  </template>
  <template v-else>
    {{ props.value }}
    <template v-if="valueEnumIndexOf !== '-1'">
      <span class="text-xs text-gray-500">[enum: {{ valueEnumIndexOf }}]</span>
    </template>
  </template>
</template>
