<script setup lang="ts">
import Button from "primevue/button";
import Chart from "primevue/chart";
import InlineMessage from "primevue/inlinemessage";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import { computed, type PropType } from "vue";
import { useRouter } from "vue-router";

import { LayoutConfigurationTable, LayoutsDifferencesTable } from "@/components/business";
import useConfirmDialog from "@/composables/use-confirm-messages";
import useNotification from "@/composables/use-notifications";
import links from "@/router/links";
import { toggleAbTestActivation } from "@/services/ab-tests-services";
import { createPinnedConfiguration, generatePinnedPayload } from "@/services/pinned-services";
import type { AbTest } from "@/types/ab-test-types";
import type { PinnedConfiguration } from "@/types/pinned-types";
import type { AdFormatSchema } from "@/types/schema-types";

const router = useRouter();
const { confirmInfo } = useConfirmDialog();
const { notifySuccess, notifyError } = useNotification();

const props = defineProps({
  abTest: { type: Object as PropType<AbTest>, default: null },
  schemas: { type: Array as PropType<AdFormatSchema[]>, default: () => [] },
  pinnedConfiguration: { type: Object as PropType<PinnedConfiguration>, default: undefined },
  refetch: {
    type: Function,
    default: null,
  },
  refetchPinned: {
    type: Function,
    default: null,
  },
});

const chartData = computed(() => ({
  labels: props.abTest.adConfigurations.map((x) => x.configuration.name),
  datasets: [
    {
      data: props.abTest.adConfigurations.map((x) => x.allocation),
    },
  ],
}));

const chartOptions = {
  plugins: {
    legend: { display: true },
  },
};

const pinAdLayout = async (layoutId: string) => {
  if (
    !(await confirmInfo(
      "Are you sure you want to pin this layout? This will stop the A/B test and create a pinned layout.",
    ))
  ) {
    return;
  }

  if (props.abTest.active) {
    await toggleAbTestActivation(props.abTest.id);
    props.refetch();
  }
  try {
    await createPinnedConfiguration(generatePinnedPayload(props.abTest, layoutId));
    props.refetchPinned();
    notifySuccess({ detail: "AbTest stopped and layout pinned" });
    router.push({ name: links.pinned });
  } catch (e) {
    notifyError({ detail: `Error while pinning layout, ${e}` });
  }
};
</script>

<template>
  <div class="grid">
    <div class="col-3 flex justify-content-center">
      <Chart type="doughnut" :data="chartData" :options="chartOptions" class="w-9" />
    </div>
    <div class="col-9">
      <TabView :scrollable="true">
        <TabPanel>
          <template #header>
            <i class="mr-2 pi pi-code" />
            Differences
          </template>
          <LayoutsDifferencesTable
            :ad-layouts="props.abTest.adConfigurations.map((it) => it.configuration)"
            :schema="schemas.find((x) => x.version === abTest.adConfigurationVersion)"
          />
        </TabPanel>
        <TabPanel v-for="adConfig in props.abTest.adConfigurations" :key="adConfig.configuration.id">
          <template #header>
            {{ adConfig.configuration.name }}
            <span
              v-if="adConfig.configuration.id === props.pinnedConfiguration?.configuration.id"
              class="ml-2 pi pi-map-marker"
              :style="{ fontSize: '1.2rem' }"
            />
          </template>
          <div class="flex justify-content-between align-items-center mb-3">
            <div>
              Allocation:
              <strong>{{ adConfig.allocation }}%</strong>
            </div>
            <div>
              <InlineMessage
                v-if="adConfig.configuration.id === props.pinnedConfiguration?.configuration.id"
                severity="success"
              >
                This Ad Layout is pinned
              </InlineMessage>
              <InlineMessage
                v-if="
                  !!props.pinnedConfiguration &&
                  adConfig.configuration.id !== props.pinnedConfiguration?.configuration.id
                "
                severity="info"
              >
                A pinned configuration already exists for this A/B Test
              </InlineMessage>
              <Button
                v-if="!!!props.pinnedConfiguration"
                label="Pin this layout"
                icon="pi pi-map-marker"
                :disabled="!!props.pinnedConfiguration"
                @click="() => pinAdLayout(adConfig.configuration.id)"
              />
            </div>
          </div>
          <LayoutConfigurationTable :ad-layout="adConfig.configuration" :schemas="props.schemas" />
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>
