<script setup lang="ts">
import Button from "primevue/button";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import InputText from "primevue/inputtext";
import Panel from "primevue/panel";
import { PropType, computed, ref } from "vue";

import useConfirmDialog from "@/composables/use-confirm-messages";
import { deleteMock } from "@/services/test-app-services";
import { IdUrl } from "@/types/test-app-types";

const { confirmDanger } = useConfirmDialog();
const props = defineProps({
  mocks: { type: Array as PropType<IdUrl[]>, default: () => [] },
  os: { type: String, default: () => "" },
  openForm: { type: Function, default: null },
  openModal: { type: Function, default: null },
  refetch: { type: Function, default: null },
});

const icon = computed(() => (props.os === "android" ? "pi pi-android" : "pi pi-apple"));
const filter = ref("");
const filteredMocks = computed(() => {
  if (!filter.value) {
    return props.mocks;
  }

  return props.mocks.filter(({ id }) => id.toLowerCase().includes(filter.value.toLowerCase()));
});
</script>

<template>
  <Panel class="flex-1 mr-4">
    <template #header>
      <div class="flex align-items-center gap-2">
        <i :class="icon"></i>
        <span class="font-bold">{{ os.toUpperCase() }}</span>
      </div>
      <div class="flex w-full justify-content-end">
        <IconField icon-position="left">
          <InputIcon class="pi pi-search"></InputIcon>
          <InputText v-model="filter" placeholder="Search" />
        </IconField>
      </div>
    </template>
    <div v-if="!mocks.length" :style="{ textAlign: 'center' }">No mock files found</div>
    <div
      v-for="{ id: mockName } in filteredMocks"
      :key="mockName"
      class="flex justify-content-between align-items-center"
    >
      <Button text icon="pi pi-qrcode" :label="mockName" @click="openModal(os, mockName)" />
      <div>
        <Button
          v-tooltip.bottom="'Edit'"
          type="button"
          icon="pi pi-pencil"
          aria-label="Edit"
          severity="primary"
          text
          rounded
          @click="openForm(os, mockName)"
        />
        <Button
          v-tooltip.bottom="'Duplicate'"
          type="button"
          icon="pi pi-copy"
          aria-label="Duplicate"
          severity="primary"
          text
          rounded
          @click="openForm(os, mockName, true)"
        />
        <Button
          v-tooltip.bottom="'Delete'"
          type="button"
          icon="pi pi-trash"
          aria-label="Delete"
          severity="danger"
          text
          rounded
          @click="
            async () => {
              if (!(await confirmDanger(`Are you sure you want to delete the mock file ${mockName}?`))) return;

              await deleteMock(os, mockName);
              refetch();
            }
          "
        />
      </div>
    </div>
  </Panel>
</template>
