<script setup lang="ts">
import { StyleValue, ref, watch } from "vue";

import MockVideo from "@/assets/mock-video.gif";

const emit = defineEmits<{
  (e: "update:duration", value: number): void;
}>();

const props = defineProps({
  isRunning: {
    type: Boolean,
    default: false,
  },
  plackbackRate: {
    type: Number,
    default: 1,
  },
  videoUrl: {
    type: String,
    default: "",
  },
  useMock: {
    type: Boolean,
    default: false,
  },
  defaultVideo: {
    type: String,
    default: "",
  },
});

const video = ref<HTMLVideoElement>();
const style: StyleValue = {
  height: "100%",
  width: "100%",
  borderRadius: "10px",
  padding: "8px",
  overflow: "hidden",
};

const intervalDuration = ref();

const playVideo = () => {
  video.value?.play();
};

const stopVideo = () => {
  video.value?.pause();
};

const getVideoDuration = () => Math.floor(video.value?.duration ?? 30);

const setCurrentTime = (time: number) => {
  if (video.value) {
    video.value.currentTime = time;
  }
};

const setMute = (isMute: boolean) => {
  if (video.value) {
    video.value.muted = isMute;
  }
};

const setVideoSrc = (src: string) => {
  if (video.value) {
    const newSrc = src.length > 0 ? src : props.defaultVideo;
    video.value.src = newSrc;

    intervalDuration.value = setInterval(() => {
      if (video.value?.readyState === 0) {
        return;
      }

      emit("update:duration", getVideoDuration());
      clearInterval(intervalDuration.value);
    }, 200);
  }
};

watch(
  () => props.plackbackRate,
  (newVal) => {
    if (newVal && video.value && video.value.playbackRate) {
      video.value.playbackRate = newVal;
    }
  },
  { immediate: true },
);

defineExpose({
  playVideo,
  stopVideo,
  getVideoDuration,
  setCurrentTime,
  setMute,
  setVideoSrc,
});
</script>

<template>
  <div>
    <video v-show="!useMock" ref="video" :style="style" muted>
      <source :src="props.videoUrl.length > 0 ? props.videoUrl : defaultVideo" type="video/mp4" />
      <track kind="captions" />
    </video>
    <img v-if="useMock && isRunning" :src="MockVideo" :style="style" alt="mock video" />
  </div>
</template>
