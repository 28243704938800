import type { RouteRecordRaw } from "vue-router";

import DummyRouterViewVue from "@/components/DummyRouterView.vue";
import PinnedConfigurationsPage from "@/pages/pinned/PinnedConfigurationsPage.vue";

import links from "./links";

export const pinnedRoutes: RouteRecordRaw[] = [
  {
    path: links.pinned,
    component: DummyRouterViewVue,
    children: [
      {
        path: "",
        component: PinnedConfigurationsPage,
        name: links.pinned,
        props: true,
        meta: { title: "Pinned configuration" },
      },
    ],
  },
];
