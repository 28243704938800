export const isHexColor = (color: string): boolean => /^#([0-9A-F]{3}){1,2}$/i.test(color);

export const formatColor = (color: string): string => `#${color.toLocaleUpperCase()}`;

const hexToRgb = (hex: string): number[] =>
  (hex || "#000")
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
    .substring(1)
    .match(/.{2}/g)
    ?.map((x) => parseInt(x, 16)) ?? [0, 0, 0];

export const hexToRgba = (hex: string, opacity: number): string =>
  `rgba( ${hexToRgb(hex).join(", ")}, ${opacity / 100})`;
