<script setup lang="ts">
import { StyleValue } from "vue";

import MockPlayable from "@/assets/mock-playable.jpg";

const props = defineProps({
  creativeUrl: {
    type: String,
    default: "",
  },
  useMock: {
    type: Boolean,
    default: false,
  },
});

const style: StyleValue = {
  height: "100%",
  width: "100%",
  borderRadius: "10px",
  padding: "8px",
  overflow: "hidden",
};
</script>

<template>
  <img v-if="props.useMock" :src="MockPlayable" :style="style" alt="mock playable" />
  <iframe v-else :src="props.creativeUrl" :style="style" title="playable" />
</template>
