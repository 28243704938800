<script setup lang="ts">
import Tag from "primevue/tag";
import { computed } from "vue";

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  pinned: {
    type: Boolean,
    default: false,
  },
});

const tagOption = computed(() => {
  if (props.pinned) {
    return { severity: "info", value: "PINNED" };
  }
  return props.active ? { severity: "success", value: "ACTIVE" } : { severity: "warning", value: "INACTIVE" };
});
</script>

<template><Tag :value="tagOption.value" :severity="tagOption.severity"></Tag></template>
