<script setup lang="ts">
import JsonEditorVue from "json-editor-vue";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import InlineMessage from "primevue/inlinemessage";
import InputText from "primevue/inputtext";
import { v4 as uuidv4 } from "uuid";
import { Mode } from "vanilla-jsoneditor";
import { type PropType, ref, computed } from "vue";

import { PlatformSelectButton } from "@/components/business";
import { transformMockToMockForm, createMock } from "@/services/test-app-services";
import { MockContent, type Mock, type MockForm } from "@/types/test-app-types";
import { parseJson, jsonToString } from "@/utils/common";

const emptyMockData: MockForm = {
  os: "ANDROID",
  filename: "",
  content: {
    id: uuidv4(),
    name: "",
    adType: "",
    status: "",
    markup: "",
    language: "",
    platform: "",
    placements: [],
    format: "",
    promotedEntity: {
      name: "",
      icon: "",
    },
  },
};

const props = defineProps({
  editMock: { type: Object as PropType<Mock>, default: null },
  isEdition: { type: Boolean, default: false },
  existingMocks: { type: Object as PropType<{ [key: string]: string[] }>, default: () => ({}) },
  closeModal: { type: Function, default: null },
});
const filenameAuto = ref(!props.isEdition && !props.editMock?.filename);
const currentData = ref(props.editMock ? { ...transformMockToMockForm(props.editMock) } : emptyMockData);

const onCancelClick = () => {
  currentData.value = emptyMockData;
  props.closeModal();
};

const onSaveClick = async () => {
  const { filename, os, content } = currentData.value;
  const data = {
    filename: filename || "",
    os: os.toLowerCase(),
    content: typeof content === "string" ? JSON.parse(content) : content,
  };
  if (!data.filename) {
    return;
  }
  await createMock(data);
  props.closeModal();
};

const existingFile = computed(
  () =>
    !props.isEdition &&
    !!(props.existingMocks[currentData.value.os.toLowerCase()] || []).find(
      (file) => file === currentData.value.filename,
    ),
);
const isFilenameValid = computed(() => !!currentData.value.filename);
const isContentArray = computed(() => {
  try {
    return Array.isArray(parseJson(currentData.value.content));
  } catch (_) {
    return false;
  }
});

const saveBtnDisabled = computed(() => existingFile.value || !isFilenameValid.value || !currentData.value.os);

const generateFilename = (content: { text: string }) => {
  if (!filenameAuto.value) {
    return;
  }
  const { name = "", adType = "", format = "" } = parseJson(content.text) as MockContent;
  currentData.value.filename = `${name.toLowerCase()}-${adType.toLowerCase()}-${format.toLowerCase()}`;
};
</script>

<template>
  <div class="m-3 mt-0 mb-5">
    <div class="formgrid grid">
      <div class="col-3">
        <p>OS</p>
        <PlatformSelectButton v-model="currentData.os" :disabled="isEdition" />
      </div>
      <div class="col-5">
        <p>Filename</p>
        <InputText
          v-model="currentData.filename"
          class="w-full"
          type="text"
          :disabled="isEdition"
          :invalid="existingFile"
          @keydown="
            () => {
              filenameAuto = false;
            }
          "
        />
      </div>
      <div
        v-if="!isEdition"
        v-tooltip.bottom="'Will generate a name with [name]-[adType]-[format]'"
        class="col-2 flex align-items-end mb-2"
      >
        <Checkbox
          v-model="filenameAuto"
          input-id="filename-auto"
          :binary="true"
          :disabled="isEdition"
          @update:model-value="
            (value: boolean) => {
              if (value) {
                generateFilename({ text: jsonToString(currentData.content) });
              }
            }
          "
        />
        <label for="filename-auto" class="ml-2">Auto-generate</label>
      </div>
    </div>
    <div class="mt-4">
      <JsonEditorVue
        v-model="currentData.content"
        :on-change="(updateContent: any) => generateFilename(updateContent)"
        :mode="Mode.text"
      />
    </div>
  </div>
  <div class="flex gap-3 justify-content-end mr-1">
    <Button label="Cancel" text @click="onCancelClick" />
    <InlineMessage v-if="existingFile" severity="warn">
      A mock file already exists for this os and filename pair.
    </InlineMessage>
    <InlineMessage v-if="isContentArray" severity="warn">
      The mock should be a single entry, but you can still save it.
    </InlineMessage>
    <Button label="Save" :disabled="saveBtnDisabled" @click="onSaveClick" />
  </div>
</template>
