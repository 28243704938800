<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  backgroundColor: {
    type: String,
    default: "#000",
  },
});

const style = computed(() => ({
  height: "100%",
  width: "100%",
  backgroundColor: props.backgroundColor,
  borderRadius: "10px",
  padding: "8px",
  overflow: "hidden",
}));
</script>

<template>
  <div class="layout-screen" :style="style"><slot /></div>
</template>
