import { z } from "zod";

const deploymentEnvironmentEnum = z.enum(["local", "dev", "staging", "prod"]);
export type DeploymentEnvironment = z.infer<typeof deploymentEnvironmentEnum>;

const environmentVariablesSchema = z.object({
  VITE_ENVIRONMENT: deploymentEnvironmentEnum,
  VITE_BASE_URL: z.string(),
  VITE_ACCOUNT_URL: z.string(),
  VITE_AUTH_DOMAIN: z.string(),
  VITE_AUTH_AUDIENCE: z.string(),
  VITE_AUTH_CLIENT_ID: z.string(),
  VITE_AUTH_CALLBACK_REDIRECT_URL: z.string(),
  VITE_AUTH_LOGOUT_REDIRECT_URL: z.string(),
  VITE_AUTH_TOKEN_PREFIX: z.string(),
  VITE_MISSIONCONTROL_URL: z.string(),
  VITE_MISSIONCONTROL_V2_URL: z.string(),
  /** optionals */
  VITE_LOG_ROCKET_APP_ID: z.string().optional(),
  VITE_SENTRY_DNS: z.string().optional(),
  VITE_SENTRY_AUTH_TOKEN: z.string().optional(),
  VITE_SENTRY_PROJECT: z.string().optional(),
  VITE_SENTRY_ORGANIZATION_SLUG: z.string().optional(),
});

export const environmentVariables = environmentVariablesSchema.parse(import.meta.env);

export const config = {
  environment: environmentVariables.VITE_ENVIRONMENT,
  baseUrl: environmentVariables.VITE_BASE_URL,
  localStoragePrefix: "voodoo-adn",
  accountUrl: environmentVariables.VITE_ACCOUNT_URL,
  missionControlUrl: environmentVariables.VITE_MISSIONCONTROL_URL,
  missionControlV2Url: environmentVariables.VITE_MISSIONCONTROL_V2_URL,
  auth0: {
    domain: environmentVariables.VITE_AUTH_DOMAIN,
    audience: environmentVariables.VITE_AUTH_AUDIENCE,
    clientId: environmentVariables.VITE_AUTH_CLIENT_ID,
    callbackRedirectUrl: `${environmentVariables.VITE_BASE_URL}${environmentVariables.VITE_AUTH_CALLBACK_REDIRECT_URL}`,
    logoutRedirectUrl: `${environmentVariables.VITE_BASE_URL}${environmentVariables.VITE_AUTH_LOGOUT_REDIRECT_URL}`,
    tokenPrefix: environmentVariables.VITE_AUTH_TOKEN_PREFIX,
  },
  loggers: {
    sentryDns: environmentVariables.VITE_SENTRY_DNS,
    logRocketAppId: environmentVariables.VITE_LOG_ROCKET_APP_ID,
  },
};
