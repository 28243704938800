<script setup lang="ts">
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import { type PropType, computed } from "vue";

import type { AdLayout } from "@/types/layout-types";
import type { AdFormatSchema } from "@/types/schema-types";
import { mapObjectToKeyValue } from "@/utils/object-mapping";

import ComputeValueDisplay from "../ComputeValueDisplay/ComputeValueDisplay.vue";
import LayoutConfigurationLabel from "../LayoutConfigurationLabel/LayoutConfigurationLabel.vue";

const props = defineProps({
  adLayout: { type: Object as PropType<AdLayout>, default: null },
  schemas: { type: Array as PropType<AdFormatSchema[]>, default: () => [] },
  schema: { type: Object as PropType<AdFormatSchema>, default: null },
});

const schemaValues = computed(() =>
  props.schema ? props.schema.values : (props.schemas || []).find((x) => x.version === props.adLayout.version)?.values,
);
const adLayoutValues = computed(() =>
  mapObjectToKeyValue(props.adLayout?.values ?? []).sort((a, b) => a.key.localeCompare(b.key)),
);
</script>
<template>
  <DataTable v-if="adLayoutValues" :value="adLayoutValues">
    <Column header="Key" :style="{ width: '25rem' }">
      <template #body="innerProps">
        <LayoutConfigurationLabel :name="innerProps.data.key" :schema-values="schemaValues" />
      </template>
    </Column>
    <Column header="Value">
      <template #body="innerProps">
        <ComputeValueDisplay :name="innerProps.data.key" :value="innerProps.data.value" :schema-values="schemaValues" />
      </template>
    </Column>
  </DataTable>
</template>
