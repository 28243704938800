<script setup lang="ts">
import SelectButton from "primevue/selectbutton";
import { computed } from "vue";

const props = defineProps({
  includeAll: {
    type: Boolean,
    default: false,
  },
  iconOnly: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const options = computed(() =>
  [
    props.includeAll && { value: "ALL", label: "ALL" },
    { value: "ANDROID", label: "Android", icon: "pi pi-android" },
    { value: "IOS", label: "iOS", icon: "pi pi-apple" },
  ].filter((x) => x),
);
</script>

<template>
  <SelectButton
    :options="options"
    data-key="value"
    option-value="value"
    option-label="label"
    :disabled="props.disabled"
  >
    <template #option="{ option }">
      <i v-if="option.icon" :class="option.icon" />
      <span v-else>
        <b>{{ option.label }}</b>
      </span>
      <span v-if="!iconOnly" class="ml-2">
        <b>{{ option.label }}</b>
      </span>
    </template>
  </SelectButton>
</template>

<style lang="scss" scoped>
.platform-select-input-component {
  line-height: 1.2rem;
}
.pi {
  font-size: 1.2rem;
}
</style>
