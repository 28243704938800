export default {
  notFound: "notFound",
  error: "error",
  login: "login",
  forbidden: "forbidden",
  logout: "logout",
  loginRedirect: "loginRedirect",

  abtests: "abtests",
  adlayouts: "adlayouts",
  pinned: "pinned",
  schemas: "schemas",
  testapp: "testapp",
};
