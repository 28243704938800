<script setup lang="ts">
import { useQuery, useQueryClient } from "@tanstack/vue-query";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import { FilterMatchMode } from "primevue/api";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import InputText from "primevue/inputtext";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import { computed, ref } from "vue";

import { LayoutConfigurationLabel } from "@/components/business";
import { getSchemas, getSchemasSemver } from "@/services/schemas-services";

useQueryClient();
const filters = ref();
const { data, isLoading } = useQuery({
  queryKey: ["schemas"],
  queryFn: getSchemas,
  staleTime: Infinity,
});

const { data: semverData } = useQuery({
  queryKey: ["semver"],
  queryFn: getSchemasSemver,
  staleTime: Infinity,
});

const schemas = computed(() => [...(data.value || [])].sort((a, b) => b.version.localeCompare(a.version)));
const schemasSemverAndroid = computed(() => (semverData.value || { ANDROID: {} }).ANDROID);
const schemasSemverIOS = computed(() => (semverData.value || { IOS: {} }).IOS);
const initFilters = () => {
  filters.value = { global: { value: null, matchMode: FilterMatchMode.CONTAINS } };
};
initFilters();
</script>

<template>
  <h1>Schemas</h1>
  <Accordion>
    <AccordionTab header="Schema by SDK Version">
      <table
        :style="{
          width: '100%',
          borderCollapse: 'collapse',
          borderSpacing: 0,
          textAlign: 'center',
        }"
      >
        <tr
          :style="{
            borderBottom: '2px solid #f2f2f2',
          }"
        >
          <th>Version</th>
          <th v-for="{ version } in schemas" :key="version">
            {{ version }}
          </th>
        </tr>
        <tr
          :style="{
            borderBottom: '1px solid #f2f2f2',
          }"
        >
          <td>Android</td>
          <td v-for="{ version } in schemas" :key="version" :style="{ padding: '1rem' }">
            {{ schemasSemverAndroid[version] }}
          </td>
        </tr>
        <tr
          :style="{
            borderBottom: '1px solid #f2f2f2',
          }"
        >
          <td>iOS</td>
          <td v-for="{ version } in schemas" :key="version" :style="{ padding: '1rem' }">
            {{ schemasSemverIOS[version] }}
          </td>
        </tr>
      </table>
    </AccordionTab>
  </Accordion>
  <TabView :scrollable="true">
    <TabPanel v-for="schema in schemas" :key="schema.id" :header="`Version: ${schema.version}`">
      <DataTable
        v-model:filters="filters"
        :global-filter-fields="['name', 'type', 'enum', 'default']"
        :value="schema.values"
        :loading="isLoading"
        sort-mode="multiple"
      >
        <template #header>
          <div class="flex justify-content-end">
            <IconField icon-position="left">
              <InputIcon class="pi pi-search"></InputIcon>
              <InputText v-model="filters['global'].value" placeholder="Search" />
            </IconField>
          </div>
        </template>
        <Column header="Name" sortable sort-field="name">
          <template #body="slotProps">
            <LayoutConfigurationLabel :name="slotProps.data.name" :schema-values="schema.values" />
          </template>
        </Column>
        <Column field="type" header="Type" sortable />
        <Column field="enum" header="Enum" />
        <Column field="default" header="Default value" />
      </DataTable>
    </TabPanel>
  </TabView>
</template>
