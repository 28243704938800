<script setup lang="ts">
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import ColorPicker from "primevue/colorpicker";
import Dropdown from "primevue/dropdown";
import SelectButton from "primevue/selectbutton";
import Slider from "primevue/slider";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import ToggleButton from "primevue/togglebutton";
import { type PropType, computed, ref } from "vue";
import { StyleValue } from "vue";

import { AdFormatSchema } from "@/types/schema-types";
import { hexToRgba } from "@/utils/color";
import { creatives } from "@/utils/preview";

import PreviewBackground from "./PreviewBackground.vue";
import PreviewButton from "./PreviewButton.vue";
import PreviewEndcard from "./PreviewEndcard.vue";
import PreviewProgressBar from "./PreviewProgressBar.vue";
import PreviewSkan from "./PreviewSkan.vue";
import PreviewStore from "./PreviewStore.vue";
import PreviewVideo from "./PreviewVideo.vue";

const props = defineProps({
  configuration: {
    type: Object as PropType<Array<{ key: string; value: string }>>,
    default: null,
  },
  showControls: {
    type: Boolean,
    default: true,
  },
  currentSchema: {
    type: Object as PropType<AdFormatSchema>,
    default: null,
  },
});

const screenStyle: StyleValue = {
  height: "667px",
  width: "375px",
  backgroundColor: "#686868",
  borderRadius: "10px",
  padding: "8px",
  position: "relative",
  overflow: "hidden",
};

// Display card
const modeOptions = computed(() => ["Video", "Endcard"]);
const previewMode = ref("Video");
const isVideo = computed(() => previewMode.value === "Video");
const alwaysDisplay = ref(true);
const showSkan = ref(true);
const showStore = ref(false);

// Time Relative
const currentTime = ref(0);
const videoDuration = ref(30);
const isRunning = ref(false);
const runInterval = ref();
const endcardDuration = 30;
const baseTime = 1000;
const timescale = ref(1);
const timescaleOptions = ref([
  { label: "1x", value: 1 },
  { label: "2x", value: 2 },
  { label: "4x", value: 4 },
]);

// Creative Relative
const isMutted = ref(true);
const hideCreatives = ref(false);
const videoRef = ref<typeof PreviewVideo>();
const useMockCreative = ref(false);
const creativeOptions = ref(creatives);

// SKAN Relative
const skanRef = ref<typeof PreviewSkan>();

// Clickable Ratio
const firstClick = ref(false);
const showClickableRatio = ref(true);
const clickableColor = ref("FF0000");
const clickableOpacity = ref(50);

const selectedCreative = ref(creativeOptions.value[0]);

const forceSkip = computed(() => props.configuration?.find(({ key }) => key === "fs")?.value ?? "false");
const interval = computed(() => baseTime / timescale.value);

const registerClick = () => {
  firstClick.value = true;
};

const stopSimulation = () => {
  if (runInterval.value) {
    clearInterval(runInterval.value);
  }
  videoRef.value?.stopVideo();
};

const switchToEndcard = () => {
  if (!isVideo.value) {
    return;
  }
  firstClick.value = false;
  currentTime.value = 0;
  videoRef.value?.stopVideo();
  skanRef.value?.resetSkan();
  previewMode.value = "Endcard";
};

const runSimulation = (play: boolean) => {
  if (!play) {
    stopSimulation();
    return;
  }

  if (currentTime.value === videoDuration.value) {
    currentTime.value = 0;
  }

  alwaysDisplay.value = false;
  if (!useMockCreative.value) {
    videoRef.value?.playVideo();
  }

  runInterval.value = setInterval(() => {
    if (!isVideo.value) {
      currentTime.value += 1;
      if (currentTime.value === endcardDuration) {
        stopSimulation();
      }
      return;
    }

    if (currentTime.value === videoDuration.value) {
      if (forceSkip.value === "false") {
        switchToEndcard();
      }
    }

    currentTime.value += 1;
  }, interval.value);
};

const resetSimulation = () => {
  stopSimulation();
  currentTime.value = 0;
  isRunning.value = false;
  previewMode.value = "Video";
  alwaysDisplay.value = true;
  videoRef.value?.setCurrentTime(0);
  firstClick.value = false;
  skanRef.value?.resetSkan();
};

const toggleMute = () => {
  videoRef.value?.setMute(!isMutted.value);
  isMutted.value = !isMutted.value;
};

const clickableRgbColor = computed(() =>
  showClickableRatio.value ? hexToRgba(`#${clickableColor.value}`, clickableOpacity.value) : "transparent",
);
</script>

<template>
  <div class="flex align-items-cente justify-content-center my-4">
    <SelectButton v-model="previewMode" :options="modeOptions" unselectable :disabled="isRunning" />
  </div>
  <div :style="screenStyle">
    <PreviewBackground :background-color="props.configuration?.find(({ key }) => key === 'scbc')?.value">
      <PreviewVideo
        v-show="isVideo && !hideCreatives"
        ref="videoRef"
        :is-running="isRunning"
        :plackback-rate="timescale"
        :use-mock="useMockCreative"
        :default-video="creativeOptions[0].videoUrl"
        @update:duration="videoDuration = $event"
      />
      <PreviewEndcard
        v-if="!isVideo && !hideCreatives"
        :creative-url="selectedCreative.playableUrl"
        :use-mock="useMockCreative"
      />
      <PreviewSkan
        v-if="showSkan"
        ref="skanRef"
        :configuration="configuration"
        :app-information="useMockCreative ? creativeOptions[0].appInformation : selectedCreative.appInformation"
        :force-display="alwaysDisplay"
        :current-time="currentTime"
        :is-video="isVideo"
        :schema="currentSchema"
      />
      <PreviewStore
        :show="showStore"
        :close-action="() => (showStore = false)"
        :app-information="useMockCreative ? creativeOptions[0].appInformation : selectedCreative.appInformation"
        :is-android="!showSkan"
      />
      <PreviewButton
        v-if="isVideo"
        :configuration="configuration"
        type="skip"
        :force-display="alwaysDisplay"
        :current-time="currentTime"
        :action="switchToEndcard"
        :container-action="registerClick"
        :click-once="firstClick"
        :interval="interval"
        :clickable-ratio-color="clickableRgbColor"
        :schema="currentSchema"
      />
      <PreviewButton
        v-if="!isVideo"
        :configuration="configuration"
        type="close"
        :force-display="alwaysDisplay"
        :current-time="currentTime"
        :action="resetSimulation"
        :container-action="registerClick"
        :click-once="firstClick"
        :interval="interval"
        :clickable-ratio-color="clickableRgbColor"
        :schema="currentSchema"
      />
      <PreviewButton
        v-if="isVideo"
        :configuration="configuration"
        type="mute"
        :action="toggleMute"
        :is-mutted="isMutted"
        :clickable-ratio-color="clickableRgbColor"
        :schema="currentSchema"
      />
      <PreviewButton
        :configuration="configuration"
        type="info"
        :clickable-ratio-color="clickableRgbColor"
        :schema="currentSchema"
        @click="showStore = true"
      />
      <PreviewButton
        :configuration="configuration"
        type="learn"
        :clickable-ratio-color="clickableRgbColor"
        :schema="currentSchema"
        @click="showStore = true"
      />
      <PreviewProgressBar
        v-if="isVideo"
        :configuration="configuration"
        :current-time="currentTime"
        :total-time="videoDuration"
        :interval="interval"
      />
    </PreviewBackground>
  </div>

  <div v-if="showControls" class="my-4">
    <div class="flex justify-content-center gap-2 mb-4">
      <Button
        v-if="showClickableRatio"
        icon="pi pi-eye"
        text
        rounded
        outlined
        severity="primary"
        @click="showClickableRatio = !showClickableRatio"
      />
      <Button
        v-if="!showClickableRatio"
        icon="pi pi-eye-slash"
        text
        rounded
        outlined
        severity="secondary"
        @click="showClickableRatio = !showClickableRatio"
      />
      <ToggleButton
        v-model="isRunning"
        on-icon="pi pi-stop"
        on-label="Stop"
        off-icon="pi pi-play"
        off-label="Play"
        @update:model-value="(val) => runSimulation(val)"
      />
      <Button label="Reset" icon="pi pi-replay" text @click="resetSimulation" />
    </div>
    <TabView>
      <TabPanel header="State">
        <div class="formgroup-inline">
          <div class="field-checkbox">
            <Checkbox v-model="alwaysDisplay" input-id="alwaysdisplay" binary :disabled="isRunning" />
            <label for="alwaysdisplay">Ignore delay</label>
          </div>
          <div class="field-checkbox">
            <Checkbox v-model="firstClick" input-id="firstClick" binary :disabled="isRunning" />
            <label for="firstClick">First click triggered</label>
          </div>
        </div>
        <div class="field grid">
          <label class="col-4">Current Time:</label>
          <div class="col-7" :style="{ marginTop: '7px' }">
            <Slider
              v-model="currentTime"
              :max="Math.max(videoDuration, endcardDuration)"
              :disabled="isRunning"
              @update:model-value="
                (val) => {
                  alwaysDisplay = val === 0;
                  if (!isRunning) {
                    videoRef?.setCurrentTime(val);
                  }
                }
              "
            />
          </div>
          <div class="col-1">{{ currentTime }}</div>
        </div>
        <div class="field grid">
          <label class="col-4">Timescale:</label>
          <div class="col-8">
            <SelectButton
              v-model="timescale"
              :options="timescaleOptions"
              option-label="label"
              option-value="value"
              unselectable
              :disabled="isRunning"
            />
          </div>
        </div>
      </TabPanel>
      <TabPanel header="Clickable Ratio">
        <div class="field-checkbox mb-4">
          <Checkbox v-model="showClickableRatio" input-id="showClickableRatio" binary />
          <label for="showClickableRatio">Display clickable zone</label>
        </div>
        <div class="field grid">
          <div class="col-6">Clickable ratio color:</div>
          <div class="col-6">
            <ColorPicker v-model="clickableColor" :disabled="!showClickableRatio" />
          </div>
        </div>
        <div class="field grid">
          <div class="col-6">Clickable ratio opacity:</div>
          <div class="col-6">
            <Slider v-model="clickableOpacity" :min="0" :max="100" :disabled="!showClickableRatio" />
          </div>
        </div>
      </TabPanel>
      <TabPanel header="Creatives">
        <div class="field grid">
          <label class="col-4">Creatives:</label>
          <div class="col-8">
            <Dropdown
              v-model="selectedCreative"
              class="w-full"
              :disabled="isRunning || useMockCreative"
              :options="creativeOptions"
              option-label="label"
              @update:model-value="
                (value) => {
                  videoRef?.setVideoSrc(value.videoUrl);
                  resetSimulation();
                }
              "
            />
          </div>
        </div>
        <div class="formgroup-inline">
          <div class="field-checkbox">
            <Checkbox
              v-model="useMockCreative"
              input-id="useMockVideo"
              binary
              :disabled="isRunning"
              @input="
                (val) => {
                  if (!val) {
                    videoDuration = videoRef?.getVideoDuration() ?? 30;
                  }
                  resetSimulation();
                }
              "
            />
            <label for="useMockVideo">Use mock creatives</label>
          </div>
          <div class="field-checkbox">
            <Checkbox v-model="hideCreatives" input-id="hideCreatives" binary :disabled="isRunning" />
            <label for="hideCreatives">Hide creatives</label>
          </div>
        </div>
        <div class="field grid">
          <label class="col-4">Video Duration:</label>
          <div class="col-7" :style="{ marginTop: '7px' }">
            <Slider v-model="videoDuration" :max="60" :min="10" :disabled="isRunning || !useMockCreative" />
          </div>
          <div class="col-1">
            {{ videoDuration }}
          </div>
        </div>
      </TabPanel>
      <TabPanel header="SKAN">
        <div class="form">
          <div class="field-checkbox">
            <Checkbox v-model="showSkan" input-id="showSkan" binary />
            <label for="showSkan">Include Skan Overlay</label>
          </div>
        </div>
        <div class="flex justify-content-center">
          <Button
            @click="
              () => {
                skanRef?.resetSkan();
              }
            "
          >
            Reset SKAN
          </Button>
        </div>
      </TabPanel>
    </TabView>
  </div>
</template>
