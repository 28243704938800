<script setup lang="ts">
/**
 Monitor the user internet connection.
 If the connection breaks, pause the application and display a message.
*/
import { useOnline } from "@vueuse/core";
import Dialog from "primevue/dialog";

const isOnline = useOnline();
</script>

<template>
  <Dialog
    class="vd-online-checker"
    :visible="!isOnline"
    modal
    :closable="false"
    position="bottom"
    header="Internet lost"
  >
    Please check your connection...
  </Dialog>
</template>
