<script setup lang="ts">
import { useQuery, useQueryClient } from "@tanstack/vue-query";
import { FilterMatchMode } from "primevue/api";
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import InputText from "primevue/inputtext";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import ToggleButton from "primevue/togglebutton";
import { computed, ref } from "vue";

import {
  FormatSelect,
  PlacementSelectButton,
  PlatformLabel,
  PlatformSelectButton,
  StatusLabel,
} from "@/components/business";
import { getAbTests } from "@/services/ab-tests-services";
import { getAdLayouts } from "@/services/ad-layouts-services";
import { findExistingPinnedConfiguration, getPinnedConfigurations } from "@/services/pinned-services";
import { getSchemas } from "@/services/schemas-services";
import type { AbTest } from "@/types/ab-test-types";

import AbTestDetail from "./components/AbTestDetail.vue";
import AbTestForm from "./components/AbTestForm.vue";
import AbTestTableMenu from "./components/AbTestTableMenu.vue";

useQueryClient();
const { isLoading, data, refetch } = useQuery({
  queryKey: ["abtests"],
  queryFn: getAbTests,
  staleTime: Infinity,
});

const { data: schemas } = useQuery({
  queryKey: ["schemas"],
  queryFn: getSchemas,
  staleTime: Infinity,
});

const { data: adLayouts } = useQuery({
  queryKey: ["adlayouts"],
  queryFn: getAdLayouts,
  staleTime: Infinity,
});

const { data: pinnedConfigurations, refetch: refetchPinned } = useQuery({
  queryKey: ["pinned"],
  queryFn: getPinnedConfigurations,
  staleTime: Infinity,
});

const abtests = computed(() =>
  [...(data.value || [])].sort((a, b) => a.adConfigurationVersion.localeCompare(b.adConfigurationVersion)),
);
const expandedRows = ref([]);
const filters = ref();
const isFormVisible = ref(false);
const includePinned = ref(true);
const platformFilter = ref("ALL");
const placementFilter = ref("ALL");
const formatFilter = ref("ALL");

const editCampaign = ref<AbTest | undefined>(undefined);
const formTitle = computed(() => (editCampaign.value ? `Edit: ${editCampaign.value?.name}` : "Create AB Test"));

const initFilters = () => {
  filters.value = { global: { value: null, matchMode: FilterMatchMode.CONTAINS } };
};

const openForm = (abTestId?: string) => {
  editCampaign.value = abTestId ? abtests.value.find(({ id }) => abTestId === id) : undefined;
  isFormVisible.value = true;
};

const closeForm = () => {
  refetch();
  isFormVisible.value = false;
  editCampaign.value = undefined;
};

const existingPinnedConfiguration = (abTest: AbTest) =>
  findExistingPinnedConfiguration(
    pinnedConfigurations.value || [],
    abTest.format,
    abTest.placement,
    abTest.platform,
    abTest.adConfigurationVersion,
  );

const schemasWithAbTests = computed(
  () =>
    schemas.value
      ?.filter((it) => abtests.value.some((x) => x.adConfigurationVersion === it.version))
      .sort((a, b) => b.version.localeCompare(a.version)) || [],
);

initFilters();
</script>

<template>
  <div class="flex justify-content-between align-items-center">
    <h1>A/B Tests</h1>
    <Button label="Create AB Test" icon="pi pi-plus" @click="() => openForm(undefined)" />
  </div>
  <TabView scrollable>
    <TabPanel v-for="schema in schemasWithAbTests" :key="schema.id" :header="`Version: ${schema.version}`">
      <DataTable
        v-model:expanded-rows="expandedRows"
        v-model:filters="filters"
        sort-mode="multiple"
        :value="
          abtests
            ?.filter((x) => x.adConfigurationVersion === schema.version)
            .filter((x) => platformFilter === 'ALL' || x.platform.toUpperCase() === platformFilter)
            .filter((x) => placementFilter === 'ALL' || x.placement.toUpperCase() === placementFilter)
            .filter((x) => formatFilter === 'ALL' || x.format.toUpperCase() === formatFilter)
            .filter((x) => includePinned || !existingPinnedConfiguration(x)) || []
        "
        data-key="id"
        :loading="isLoading"
        :global-filter-fields="['name', 'platform', 'id', 'format', 'placement']"
        removable-sort
      >
        <template #header>
          <div class="flex justify-content-end">
            <div class="flex gap-2">
              <ToggleButton
                v-model="includePinned"
                on-icon="pi pi-eye"
                off-icon="pi pi-eye-slash"
                on-label="Pinned included"
                off-label="Pinned excluded"
              />
              <FormatSelect v-model="formatFilter" include-all />
              <PlacementSelectButton v-model="placementFilter" include-all :unselectable="false" />
              <PlatformSelectButton v-model="platformFilter" include-all icon-only />

              <IconField icon-position="left">
                <InputIcon class="pi pi-search"></InputIcon>
                <InputText v-model="filters['global'].value" placeholder="Search" />
              </IconField>
            </div>
          </div>
        </template>
        <template #empty>
          <div class="flex justify-content-center align-items-center">
            <div class="text-center">
              <h3>No A/B Tests found</h3>
              <p>Change filter to display existing A/B Tests</p>
            </div>
          </div>
        </template>
        <Column expander />
        <Column header="Status" sortable sort-field="active">
          <template #body="slotProps">
            <StatusLabel
              :active="slotProps.data.active"
              :pinned="!!existingPinnedConfiguration(slotProps.data)"
            ></StatusLabel>
          </template>
        </Column>
        <Column field="id" header="Id" sortable />
        <Column field="name" header="Name" sortable />
        <Column field="description" header="Description" />
        <Column header="Platform" sortable sort-field="platform">
          <template #body="slotProps">
            <PlatformLabel :platform="slotProps.data.platform" />
          </template>
        </Column>
        <Column field="placement" header="Placement" sortable />
        <Column field="format" header="Format" sortable />
        <Column>
          <template #body="slotProps">
            <AbTestTableMenu
              :ab-test="slotProps.data"
              :open-form="openForm"
              :refresh="refetch"
              :is-pinned="!!existingPinnedConfiguration(slotProps.data)"
            />
          </template>
        </Column>
        <template #expansion="slotProps">
          <AbTestDetail
            :ab-test="slotProps.data"
            :schemas="schemas"
            :pinned-configuration="existingPinnedConfiguration(slotProps.data)"
            :refetch="refetch"
            :refetch-pinned="refetchPinned"
          ></AbTestDetail>
        </template>
      </DataTable>
    </TabPanel>
  </TabView>
  <Dialog v-model:visible="isFormVisible" maximizable modal :header="formTitle" :style="{ width: '75vw' }">
    <AbTestForm
      :schemas="schemas"
      :layouts="adLayouts"
      :original-ab-test="editCampaign"
      :close-modal="closeForm"
      :pinned-configurations="pinnedConfigurations"
    />
  </Dialog>
</template>
