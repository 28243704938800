<script setup lang="ts">
import Button from "primevue/button";
import Menu from "primevue/menu";
import { ref, computed } from "vue";

import { AppHeader } from "@/components/business";
import useAuthentication from "@/composables/use-authentication";
import { menu, userMenu } from "@/router/menu";

const refMenu = ref();
const { user, logout } = useAuthentication();

const computedUserMenu = computed(() =>
  user.value?.email
    ? [
        {
          label: user.value.email,
          disabled: true,
        },
        { separator: true },
        ...userMenu,
      ]
    : userMenu,
);

if (!user.value?.isRegistered) {
  logout();
}
</script>

<template>
  <div class="root-page">
    <AppHeader :items="menu">
      <template #end>
        <div class="flex align-items-center column-gap-3">
          <div>
            <Button
              icon="pi pi-user"
              class="p-button-secondary p-button-rounded p-button-outlined"
              aria-controls="user_menu"
              @click="(e) => refMenu.toggle(e)"
            />
            <Menu id="user_menu" ref="refMenu" class="w-14rem" :model="computedUserMenu" :popup="true" />
          </div>
        </div>
      </template>
    </AppHeader>
    <div class="router-container">
      <router-view />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.root-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.router-container {
  margin: auto;
  padding: 2rem;
  position: relative;
  flex: 1;
  width: 100%;
}
</style>
