import type { RouteRecordRaw } from "vue-router";

import DummyRouterViewVue from "@/components/DummyRouterView.vue";
import AbTestPage from "@/pages/abtests/AbTestsPage.vue";

import links from "./links";

export const abtestsRoutes: RouteRecordRaw[] = [
  {
    path: links.abtests,
    component: DummyRouterViewVue,
    children: [
      {
        path: "",
        component: AbTestPage,
        name: links.abtests,
        props: true,
        meta: { title: "Ab Tests" },
      },
    ],
  },
];
