import type { KeyValue, KeyValueParsed } from "@/types/common-types";
import type { AdLayout } from "@/types/layout-types";
import type { AdFormatSchema, AdFormatSchemaValue } from "@/types/schema-types";

import { getMissionControlApi } from "./helper";

const instance = getMissionControlApi("api/ad-layouts");

const overrideDefaultValue = {
  lmbp: "3",
  lmbtc: "#FFFFFF",
};

const getDefaultValue = (schemaValue: AdFormatSchemaValue): string => {
  const override = overrideDefaultValue[schemaValue.name];

  if (override) {
    return override;
  }

  return overrideDefaultValue[schemaValue.name]?.toString() || schemaValue.type === "enum"
    ? Math.max(schemaValue.enum?.indexOf(schemaValue.default) ?? 0, 0).toString()
    : schemaValue.default;
};

export const generateAdLayoutFromSchema = (schema?: AdFormatSchema): AdLayout => ({
  id: "",
  name: "",
  version: schema?.version ?? "1",
  values: schema?.values.reduce((acc, value) => ({ ...acc, [value.name]: getDefaultValue(value) }), {}) ?? {},
});

export const addMissingValuesToAdLayout = (layout: AdLayout, schema?: AdFormatSchema): AdLayout => {
  const layoutValues = Object.keys(layout.values);
  const schemaValues = schema?.values.map((x) => x.name) ?? [];
  const newValues = schemaValues.reduce((acc, value) => {
    if (layoutValues.includes(value)) {
      return { ...acc, [value]: layout.values[value] };
    }
    return {
      ...acc,
      [value]: getDefaultValue(
        schema?.values.find((x) => x.name === value) ?? { name: value, description: "", type: "text", default: "" },
      ),
    };
  }, {});
  return { ...layout, values: newValues };
};

export const parseConfigurationValues = (values: KeyValue[], schema?: AdFormatSchema): KeyValueParsed[] =>
  values.map(({ key, value }) => {
    const schemaValue = schema?.values.find((x) => x.name === key);
    if (!schemaValue) {
      return { key, value };
    }
    if (schemaValue.type === "numeric" || schemaValue.type === "enum") {
      return { key, value: Number(value) };
    }
    if (schemaValue.type === "boolean") {
      return { key, value: value.toString() === "1" || value.toLowerCase() === "true" ? 1 : 0 };
    }
    return { key, value };
  });

export const getAdLayouts = (): Promise<AdLayout[]> => instance.get("").then((x) => x.data);

export const createAdLayout = (layout: AdLayout): Promise<AdLayout> =>
  instance.post("", { ...layout, id: null }).then((x) => x.data);

export const updateAdLayout = (layout: AdLayout): Promise<AdLayout> =>
  instance.put(layout.id, layout).then((x) => x.data);

export const deleteAdLayout = (layoutId: string): Promise<boolean> => instance.delete(layoutId).then((_) => true);
