<script setup lang="ts">
import Button from "primevue/button";
import Fieldset from "primevue/fieldset";
import InputText from "primevue/inputtext";
import Message from "primevue/message";
import type { PropType } from "vue";
import { computed, ref } from "vue";

import { LayoutConfigurationInput, LayoutConfigurationLabel, LayoutPreview } from "@/components/business";
import useNotification from "@/composables/use-notifications";
import {
  generateAdLayoutFromSchema,
  addMissingValuesToAdLayout,
  parseConfigurationValues,
  createAdLayout,
  updateAdLayout,
} from "@/services/ad-layouts-services";
import type { AdLayout } from "@/types/layout-types";
import type { AdFormatSchema } from "@/types/schema-types";
import { getFieldsByPlacement, mapKeysWithDuplicateValues, getLayoutConfigurationLabel } from "@/utils/ad-layout";
import { NOTIFICATION_DURATION } from "@/utils/common";
import { mapKeyValueToObject, mapObjectToKeyValue } from "@/utils/object-mapping";

const { notifySuccess, notifyError } = useNotification();

const props = defineProps({
  editLayout: {
    type: Object as PropType<AdLayout>,
    default: undefined,
  },
  schema: {
    type: Object as PropType<AdFormatSchema>,
    default: undefined,
  },
  closeForm: {
    type: Function,
    default: null,
  },
});

const currentData = ref(
  props.editLayout
    ? { ...addMissingValuesToAdLayout(props.editLayout, props.schema) }
    : { ...generateAdLayoutFromSchema(props.schema) },
);
const configuration = ref(mapObjectToKeyValue(currentData.value.values).sort((a, b) => a.key.localeCompare(b.key)));

console.log("currentData", currentData.value.values);

const onCancelClick = () => {
  props.closeForm?.();
};

const onSaveClick = async () => {
  const layoutToSave = {
    ...currentData.value,
    values: mapKeyValueToObject(parseConfigurationValues(configuration.value, props.schema)),
  } as AdLayout;

  try {
    if (!currentData.value.id) {
      await createAdLayout(layoutToSave);
    } else {
      await updateAdLayout(layoutToSave);
    }
    notifySuccess({ detail: "Ad layout saved successfully.", life: NOTIFICATION_DURATION });
    props.closeForm?.();
  } catch (e) {
    notifyError({ detail: `Error saving ad layout. ${e}` });
  }
};

const duplicatedValues = computed(() => mapKeysWithDuplicateValues(configuration.value));
const fieldsByPlacement = computed(() => getFieldsByPlacement(configuration.value));
</script>

<template>
  <div class="m-3 mt-0 mb-5">
    <div class="field">
      <label for="name">Name</label>
      <InputText id="name" v-model="currentData.name" class="w-full" :disabled="!!currentData.id" />
    </div>
    <div class="field">
      <label for="version">Version</label>
      <InputText id="version" v-model="currentData.version" class="w-full" disabled />
    </div>
    <div class="flex gap-4">
      <div class="flex-grow-1">
        <template v-for="placement in Object.entries(fieldsByPlacement)" :key="placement[0]">
          <Fieldset :legend="placement[0].toUpperCase()" toggleable class="mb-2">
            <div class="formgrid grid">
              <template v-for="field in placement[1]" :key="field.key">
                <div class="mb-3 col-6 flex align-items-center">
                  <LayoutConfigurationLabel :name="field.key" :schema-values="schema?.values" />
                </div>
                <div class="mb-3 col-6">
                  <LayoutConfigurationInput
                    :model-value="field.value"
                    :name="field.key"
                    :schema-values="schema?.values"
                    :error="duplicatedValues.includes(field.key)"
                    @update:model-value="
                      (value) => {
                        (configuration.find((x) => x.key === field.key) ?? { key: field.key, value: '' }).value =
                          String(value);
                      }
                    "
                  />
                </div>
              </template>
            </div>
          </Fieldset>
        </template>
      </div>
      <div>
        <div style="position: sticky; top: 10px">
          <h2>Preview</h2>
          <LayoutPreview :configuration="configuration" :current-schema="schema" />
        </div>
      </div>
    </div>
    <Message v-if="duplicatedValues.length > 0" severity="warn" :closable="false">
      <p>
        <strong>{{ duplicatedValues.length }}</strong>
        duplicated values found :
        <strong>{{ duplicatedValues.map(getLayoutConfigurationLabel).join(", ") }}</strong>
      </p>
    </Message>
    <div class="flex gap-3 justify-content-end mt-8 mr-1">
      <Button label="Cancel" text @click="onCancelClick" />
      <Button label="Save" :disabled="!currentData.name || duplicatedValues.length > 0" @click="onSaveClick" />
    </div>
  </div>
</template>
