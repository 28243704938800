import { AbTest } from "@/types/ab-test-types";
import { KeyValue } from "@/types/common-types";
import { AdLayout, AdLayoutDifference } from "@/types/layout-types";
import { PinnedConfiguration } from "@/types/pinned-types";
import { AdFormatSchema } from "@/types/schema-types";

const keyPlacement: { [key: string]: string[] } = {
  cbp: ["endcard"],
  sbp: ["video"],
  ibp: ["video", "endcard"],
  mbp: ["video"],
  lmbp: ["video", "endcard"],
};

const startFieldByPlacement: { [key: string]: string } = {
  cb: "endcard",
  fs: "video",
  ib: "common",
  lmb: "common",
  mb: "video",
  pb: "video",
  sb: "video",
  sk: "skan",
};

const placementOrder = ["common", "video", "endcard", "skan", "misc"];

export const mapKeysWithDuplicateValues = (configValues: KeyValue[]): string[] =>
  Object.values(
    configValues.reduce((acc, current) => {
      (keyPlacement[current.key] || []).forEach((placement) => {
        const key = `${current.value}-${placement}`;
        acc[key] = [...(acc[key] || []), current.key];
      });
      return acc;
    }, <{ [key: string]: string[] }>{}),
  ).reduce((acc, current) => (current.length > 1 ? [...acc, ...current] : acc), []);

export const getFieldsByPlacement = (fields: KeyValue[]): { [key: string]: KeyValue[] } => {
  const startingFields = Object.keys(startFieldByPlacement);
  const fieldsByPlacement = fields.reduce((acc, current) => {
    const startField = startingFields.find((field) => current.key.startsWith(field)) || "";
    const key = (startFieldByPlacement[startField] as string) || "misc";
    acc[key] = [...(acc[key] || []), current];
    return acc;
  }, <{ [key: string]: KeyValue[] }>{});

  return placementOrder.reduce((acc, current) => ({ ...acc, [current]: fieldsByPlacement[current] || [] }), {});
};

export const layoutConfigurationLabels: { [key: string]: string } = {
  b: "Get banner",
  cbc: "Close button color",
  cbcr: "Close button clickable ratio",
  cbde: "Close button delay",
  cbp: "Close button position",
  cbs: "Close button size",
  cbt: "Close button type",
  fs: "Force Skip",
  ibc: "Information button color",
  ibp: "Information button position",
  ibs: "Information button size",
  lmbc: "Learn more button color",
  lmbe: "Learn more button enable",
  lmbp: "Learn more button position",
  lmbs: "Learn more button size",
  lmbt: "Learn more button type",
  lmbtc: "Learn more button text color",
  mbc: "Mute button color",
  mbp: "Mute button position",
  mbs: "Mute button size",
  pbc: "Progress bar color",
  pbe: "Progress bar enable",
  sbc: "Skip button color",
  sbcr: "Skip button clickable ratio",
  sbde: "Skip button delay",
  sbe: "Skip button enable",
  sbp: "Skip button position",
  sbs: "Skip button size",
  sbt: "Skip button type",
  scbc: "Scene background color",
  skcd: "SKAN overlay companion delay",
  skd: "SKAN overlay display",
  skp: "SKAN overlay position",
  sksdd: "SKAN overlay dismiss delay",
  skvd: "SKAN overlay video delay",
  ts: "Transition style",
};

export const getLayoutConfigurationLabel = (key: string): string => `${layoutConfigurationLabels[key]} (${key})`;

export const isLayoutUsed = (id: string, abtests: AbTest[], pinnedConfigurations: PinnedConfiguration[]): boolean =>
  abtests.some((abtest) => abtest.adConfigurations.find((x) => x.configuration.id === id)) ||
  pinnedConfigurations.some((pinned) => pinned.configuration.id === id);

export const getAbTestsWithLayout = (id: string, abtests: AbTest[]): AbTest[] =>
  abtests.filter((abtest) => abtest.adConfigurations.find((x) => x.configuration.id === id));

export const getPinnedConfigurationsWithLayout = (
  id: string,
  pinnedConfigurations: PinnedConfiguration[],
): PinnedConfiguration[] => pinnedConfigurations.filter((pinned) => pinned.configuration.id === id);

export const getAdLayoutDifferences = (adLayouts: AdLayout[]): AdLayoutDifference[] =>
  adLayouts
    .reduce((acc, current) => {
      Object.entries(current.values).forEach(([key, value]) => {
        const currentKey = acc.find((y) => y.key === key);
        if (currentKey) {
          currentKey.values.push({ layoutId: current.id, value });
        } else {
          acc.push({ key, values: [{ layoutId: current.id, value }] });
        }
      });
      return acc;
    }, <AdLayoutDifference[]>[])
    .filter((conf) => conf.values.map((x) => x.value).some((x, _, arr) => x !== arr[0]));

export const getValue = (
  keyValue: KeyValue | undefined,
  schema: AdFormatSchema | undefined,
  defaultValue: string,
): string => {
  if (!keyValue) {
    return defaultValue;
  }
  const { key, value } = keyValue;
  const schemaField = schema?.values.find((x) => x.name === key);
  return schemaField?.enum ? schemaField.enum.at(parseInt(value, 10)) || defaultValue : value;
};
