<script setup lang="ts">
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import { PropType, computed } from "vue";

import type { AdLayout } from "@/types/layout-types";
import type { AdFormatSchema } from "@/types/schema-types";
import { getAdLayoutDifferences } from "@/utils/ad-layout";

import ComputeValueDisplay from "../ComputeValueDisplay/ComputeValueDisplay.vue";
import LayoutConfigurationLabel from "../LayoutConfigurationLabel/LayoutConfigurationLabel.vue";

const props = defineProps({
  adLayouts: {
    type: Array as PropType<AdLayout[]>,
    default: () => [],
  },
  schema: {
    type: Object as PropType<AdFormatSchema>,
    default: null,
  },
});

const adLayoutsDifferences = computed(() => getAdLayoutDifferences(props.adLayouts));
</script>

<template>
  <DataTable :value="adLayoutsDifferences">
    <template #empty>
      <div class="flex justify-content-center align-items-center">
        <div class="text-center">
          <h3>No differences found</h3>
          <p>There are no differences between the layouts of this A/B Test</p>
        </div>
      </div>
    </template>
    <Column header="Key" :style="{ width: '25rem' }">
      <template #body="innerProps">
        <LayoutConfigurationLabel :name="innerProps.data.key" :schema-values="schema.values" />
      </template>
    </Column>
    <Column v-for="adLayout in adLayouts" :key="adLayout.id" :header="adLayout.name">
      <template #body="innerProps">
        <ComputeValueDisplay
          :name="innerProps.data.key"
          :value="innerProps.data.values.find(({ layoutId }) => layoutId === adLayout.id).value"
          :schema-values="schema.values"
        />
      </template>
    </Column>
  </DataTable>
</template>
