import { RouteRecordRaw } from "vue-router";

import DummyRouterViewVue from "@/components/DummyRouterView.vue";
import TestAppPage from "@/pages/testapp/TestAppPage.vue";

import links from "./links";

export const testAppRoutes: RouteRecordRaw[] = [
  {
    path: links.testapp,
    component: DummyRouterViewVue,
    children: [
      {
        path: "",
        component: TestAppPage,
        name: links.testapp,
        props: true,
        meta: { title: "Test App - Mock" },
      },
    ],
  },
];
