<script setup lang="ts">
import { ComputedRef, PropType, StyleValue, computed } from "vue";

import { AdFormatSchema } from "@/types/schema-types";
import { getValue } from "@/utils/ad-layout";
import { getPositionFromPlacement, getButtonSize, getCloseButtonSize, getFontSize } from "@/utils/preview";

import PreviewClickableRatio from "./PreviewClickableRatio.vue";
import PreviewProgressButton from "./PreviewProgressButton.vue";

const props = defineProps({
  configuration: {
    type: Object as PropType<Array<{ key: string; value: string }>>,
    default: null,
  },
  schema: {
    type: Object as PropType<AdFormatSchema>,
    default: null,
  },
  type: {
    type: String as PropType<"close" | "skip" | "mute" | "learn" | "info">,
    default: "close",
  },
  action: {
    type: Function as PropType<() => void>,
    default: null,
  },
  currentTime: {
    type: Number,
    default: 0,
  },
  totalTime: {
    type: Number,
    default: 30,
  },
  forceDisplay: {
    type: Boolean,
    default: true,
  },
  interval: {
    type: Number,
    default: 1000,
  },
  isMutted: {
    type: Boolean,
    default: false,
  },
  clickOnce: {
    type: Boolean,
    default: false,
  },
  containerAction: {
    type: Function as PropType<() => void>,
    default: null,
  },
  clickableRatioColor: {
    type: String,
    default: "#FF000050",
  },
});

const getValueFromConfig = (confKey: string, defaultValue: string) =>
  getValue(
    props.configuration?.find(({ key }) => key === confKey),
    props.schema,
    defaultValue,
  );

const skipButtonType = computed(() =>
  props.type === "skip" ? getValueFromConfig("sbt", "PROGRESS_WITH_CLOSE") : "SIMPLE_CROSS",
);

const innerConf = computed(() => {
  switch (props.type) {
    case "close":
      return {
        prefix: "cb",
        icon: "pi pi-times",
      };
    case "skip":
      return {
        prefix: "sb",
        icon: skipButtonType.value === "PROGRESS_WITH_CLOSE" ? "pi pi-times-circle" : "pi pi-times",
      };
    case "mute":
      return {
        prefix: "mb",
        icon: props.isMutted ? "pi pi-volume-off" : "pi pi-volume-up",
      };
    case "learn":
      return {
        prefix: "lmb",
        icon: "",
        text: "Install",
      };
    case "info":
      return {
        prefix: "ib",
        icon: "pi pi-info-circle",
      };
    default:
      return { prefix: "", icon: "" };
  }
});

const isSkipOrClose = computed(() => props.type === "skip" || props.type === "close");
const isLearnMore = computed(() => props.type === "learn");

const display = computed(
  () => props.configuration?.find(({ key }) => key === `${innerConf.value.prefix}e`)?.value ?? "true",
);
const position = computed(() => getValueFromConfig(`${innerConf.value.prefix}p`, "TOP_LEFT"));
const color = computed(
  () => props.configuration?.find(({ key }) => key === `${innerConf.value.prefix}c`)?.value ?? "#FFF",
);
const textColor = computed(
  () => props.configuration?.find(({ key }) => key === `${innerConf.value.prefix}tc`)?.value ?? "#FFF",
);
const size = computed(() => getValueFromConfig(`${innerConf.value.prefix}s`, "SMALL"));
const delay = computed(() => getValueFromConfig(`${innerConf.value.prefix}de`, "SEC_15").split("_")[1] ?? "-1");
const clickableRatio = computed(() => getValueFromConfig(`${innerConf.value.prefix}cr`, "PERCENT_100"));
const remaining = computed(() => Number.parseInt(delay.value, 10) - props.currentTime);

const style: ComputedRef<StyleValue> = computed(() => ({
  position: "absolute",
  cursor: "pointer",
  ...getPositionFromPlacement(position.value),
}));

const shadow = "#8F8F8F 1px 0 10px";
const enabled = computed(() => props.forceDisplay || delay.value === "-1" || remaining.value <= 0);
</script>

<template>
  <div
    v-if="display === 'true' || display === '1'"
    :style="style"
    @click="
      () => {
        if (enabled) {
          containerAction?.();
        }
      }
    "
    @keypress="() => {}"
  >
    <PreviewClickableRatio
      v-if="enabled && !isLearnMore"
      :clickable-ratio="clickableRatio"
      :click-once="clickOnce"
      :clickable-ratio-color="clickableRatioColor"
      :button-size="
        skipButtonType === 'PROGRESS_WITH_CLOSE' || !isSkipOrClose ? getButtonSize(size) : getCloseButtonSize(size)
      "
      :action="action"
    />
    <template v-if="skipButtonType === 'NUMERICAL_WITH_CLOSE' && remaining > 0">
      <p
        :style="{
          color: color,
          fontSize: getFontSize(size),
          margin: 0,
          textShadow: shadow,
        }"
      >
        {{ remaining }}
      </p>
    </template>
    <template v-else-if="skipButtonType === 'PROGRESS_WITH_CLOSE'">
      <PreviewProgressButton
        :value="(currentTime / Number.parseInt(delay, 10)) * 100"
        :color="color"
        :size="getButtonSize(size)"
        :interval="interval"
      />
    </template>
    <template v-else-if="enabled">
      <i
        :class="innerConf.icon"
        :style="{
          color: isLearnMore ? textColor : color,
          textShadow: !isLearnMore ? shadow : 'none',
          fontSize: isSkipOrClose ? getCloseButtonSize(size) : getButtonSize(size),
          fontStyle: 'normal',
          backgroundColor: isLearnMore ? `${color}` : 'none',
          padding: isLearnMore ? '5px' : '0',
          borderRadius: isLearnMore ? '5px' : '0',
        }"
      >
        {{ innerConf.text }}
      </i>
    </template>
  </div>
</template>
