import type { IdUrl, Mock, MockForm } from "@/types/test-app-types";

import { getMissionControlApi } from "./helper";

const instance = getMissionControlApi("api/test-app");

export const getAdLayoutEndpointsByVersion = (): Promise<IdUrl[]> =>
  instance.get("ad-layouts/version").then((x) => x.data);

export const getAdLayoutEndpoints = (): Promise<IdUrl[]> => instance.get("ad-layouts").then(({ data }) => data);

export const getAdLayoutEndpoint = (id: string): Promise<IdUrl> =>
  instance.get(`ad-layouts/${id}`).then(({ data }) => data);

export const listAndroidMocks = (): Promise<IdUrl[]> => instance.get(`mock/android`).then(({ data }) => data);
export const listIosMocks = (): Promise<IdUrl[]> => instance.get(`mock/ios`).then(({ data }) => data);
export const getMock = (os: string, filename: string): Promise<object> =>
  instance.get(`mock/${os}/${filename}`).then(({ data }) => data);
export const createMock = (mock: Mock): Promise<boolean> => instance.post(`mock`, mock).then(({ data }) => data);
export const deleteMock = (os: string, filename: string): Promise<boolean> =>
  instance.delete(`mock/${os}/${filename}`).then(({ data }) => data);

export const transformMockToMockForm = (mock: Mock): MockForm => {
  const { filename, content } = mock;
  return {
    filename,
    os: mock.os.toUpperCase(),
    content,
  };
};
