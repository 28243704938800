<script setup lang="ts">
import SelectButton from "primevue/selectbutton";
import { computed } from "vue";

import { placements } from "@/types/common-types";

const props = defineProps({
  includeAll: {
    type: Boolean,
    default: false,
  },
  unselectable: {
    type: Boolean,
    default: true,
  },
});
const options = computed(() => [props.includeAll && "ALL", ...placements].filter((x) => x));
</script>

<template>
  <SelectButton :options="options" :unselectable="unselectable" />
</template>
