import vueAuth0, { type FetchUser } from "@voodoo.io/vue-auth0";
import jwtDecode, { type JwtPayload } from "jwt-decode";

import { config } from "@/config";

export interface User {
  id: string;
  email: string;
  organization: {
    displayName: string;
  };
  permissions: string[];
  isRegistered: boolean;
  exp?: number;
}

interface VoodooJwtPayload extends JwtPayload {
  org_id: string;
  permissions: string[];
  [key: string]: string | string[] | undefined | number;
}

const getUserFromJwtToken = (token: VoodooJwtPayload): User => {
  const user: User = {
    id: token[`${config.auth0.tokenPrefix}/email`] as string,
    email: token[`${config.auth0.tokenPrefix}/email`] as string,
    organization: { displayName: token.org_id },
    permissions: token.permissions,
    isRegistered: token.exp ? new Date(token.exp * 1000) > new Date() : true,
  };

  return user;
};

const fetchUser: FetchUser<User> | never = async (accessToken: string) => {
  const jwtDecoded = jwtDecode<VoodooJwtPayload>(accessToken);
  const user = getUserFromJwtToken(jwtDecoded);
  return { user };
};

const accessTokenStorageKey = `${config.localStoragePrefix ?? ""}-${config.environment}-voodoo-app-access-token`;

export default vueAuth0<User>({
  loginUrl: "/login",
  auth0LogoutRedirectUrl: config.auth0.logoutRedirectUrl,
  auth0Domain: config.auth0.domain,
  auth0ClientId: config.auth0.clientId,
  auth0CallbackRedirectUrl: config.auth0.callbackRedirectUrl,
  auth0Audience: config.auth0.audience,
  accessTokenStorageKey,
  fetchUser,
});
