export const formats = ["PLAYABLE", "VIDEO", "VIDEO_IEC", "VIDEO_PLAYABLE"];
export type Format = (typeof formats)[number];

export const placements = ["FS", "RV"];
export type Placement = (typeof placements)[number];

export const platforms = ["ANDROID", "IOS"];
export type Platform = (typeof platforms)[number];

export type KeyValue = {
  key: string;
  value: string;
};

export type KeyValueParsed = {
  key: string;
  value: string | boolean | number;
};
