import { type RouteRecordRaw } from "vue-router";

import DummyRouterViewVue from "@/components/DummyRouterView.vue";
import AdLayoutsPage from "@/pages/adlayouts/AdLayoutsPage.vue";

import links from "./links";

export const adlayoutsRoutes: RouteRecordRaw[] = [
  {
    path: links.adlayouts,
    component: DummyRouterViewVue,
    children: [
      {
        path: "",
        component: AdLayoutsPage,
        name: links.adlayouts,
        props: true,
        meta: { title: "Ad Layouts" },
      },
    ],
  },
];
