<script setup lang="ts">
import Tag from "primevue/tag";
import { computed } from "vue";

const props = defineProps({
  platform: { type: String, default: "" },
});

const isAndroid = computed(() => props.platform?.toLocaleLowerCase() === "android");

const icon = computed(() => (isAndroid.value ? "pi pi-android" : "pi pi-apple"));
const label = computed(() => (isAndroid.value ? "Android" : "IOS"));
const severity = computed(() => (isAndroid.value ? "success" : "info"));
</script>

<template>
  <Tag :value="label" :icon="icon" :severity="severity"></Tag>
</template>
