<script setup lang="ts">
import type { PropType } from "vue";
import { computed } from "vue";

import type { AdFormatSchemaValue } from "@/types/schema-types";
import { layoutConfigurationLabels } from "@/utils/ad-layout";

const props = defineProps({
  name: { type: String, default: "" },
  schemaValues: { type: Array as PropType<AdFormatSchemaValue[]>, default: () => [] },
});

const schemaValue = props.schemaValues.find((it) => it.name === props.name);
const labelFromSchema = computed(() => schemaValue?.label ?? "");
const descriptionFromSchema = computed(() => schemaValue?.description ?? "");
</script>

<template>
  <span v-tooltip="descriptionFromSchema">
    {{ labelFromSchema || layoutConfigurationLabels[props.name] }}
    <i>({{ props.name }})</i>
  </span>
</template>
