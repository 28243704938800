<script setup lang="ts">
import Button from "primevue/button";
import Dropdown, { type DropdownChangeEvent } from "primevue/dropdown";
import Fieldset from "primevue/fieldset";
import InlineMessage from "primevue/inlinemessage";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import Slider from "primevue/slider";
import SplitButton from "primevue/splitbutton";
import type { PropType } from "vue";
import { computed, ref } from "vue";

import { FormatSelect, PlacementSelectButton, PlatformSelectButton } from "@/components/business";
import useNotification from "@/composables/use-notifications";
import { createAbTest, updateAbTest } from "@/services/ab-tests-services";
import { findExistingPinnedConfiguration } from "@/services/pinned-services";
import type { AbTest } from "@/types/ab-test-types";
import type { AdLayout } from "@/types/layout-types";
import type { PinnedConfiguration } from "@/types/pinned-types";
import type { AdFormatSchema } from "@/types/schema-types";

const emptyAbTest: AbTest = {
  id: "",
  active: false,
  name: "",
  description: "",
  placement: "FS",
  format: "VIDEO_PLAYABLE",
  adConfigurationVersion: "",
  adConfigurations: [],
  platform: "ANDROID",
};

const props = defineProps({
  schemas: { type: Array as PropType<AdFormatSchema[]>, default: () => [] },
  layouts: { type: Array as PropType<AdLayout[]>, default: () => [] },
  originalAbTest: { type: Object as PropType<AbTest>, default: undefined },
  closeModal: { type: Function, default: null },
  pinnedConfigurations: { type: Array as PropType<PinnedConfiguration[]>, default: () => [] },
});

const { notifySuccess, notifyError } = useNotification();

const currentData = ref(
  props.originalAbTest
    ? { ...props.originalAbTest, adConfigurations: [...props.originalAbTest.adConfigurations.map((it) => ({ ...it }))] }
    : emptyAbTest,
);

const previousVersion = ref(currentData.value.adConfigurationVersion);
const selectedAdLayout = ref("");

const schemaValues = computed(() => props.schemas.map((x) => x.version));
const filteredLayouts = computed(() =>
  !currentData.value.adConfigurationVersion
    ? []
    : props.layouts
        .filter((x) => x.version === currentData.value.adConfigurationVersion)
        .filter((x) => !currentData.value.adConfigurations.find((c) => c.configuration.id === x.id))
        .map(({ id, name }) => ({
          id,
          name,
        })),
);
const allocationTotal = computed(() => currentData.value.adConfigurations.reduce((acc, it) => acc + it.allocation, 0));

const onCancelClick = () => {
  currentData.value = emptyAbTest;
  if (props.closeModal) {
    props.closeModal();
  }
};

const onSaveClick = async () => {
  const abTest = currentData.value;
  if (!abTest) {
    notifyError({
      detail: "No AbTest found to create",
    });
    return;
  }

  try {
    if (!abTest.id) {
      await createAbTest(abTest);
    } else {
      await updateAbTest(abTest);
    }
    notifySuccess({ detail: `The AB Test was successfully saved`, life: 5000 });
    props.closeModal();
  } catch (err) {
    notifyError({ detail: `${err}` });
  }
};

const onChangeSchemaVersion = (event: DropdownChangeEvent) => {
  const { value } = event;
  if (value !== previousVersion.value) {
    currentData.value.adConfigurations = [];
    previousVersion.value = value;
    selectedAdLayout.value = filteredLayouts.value[0]?.id ?? "";
  }
};

const onAddAdLayout = () => {
  const adConfiguration = props.layouts.find((x) => x.id === selectedAdLayout.value);
  if (adConfiguration) {
    currentData.value.adConfigurations = [
      ...currentData.value.adConfigurations,
      { allocation: 0, configuration: adConfiguration },
    ];
    selectedAdLayout.value = filteredLayouts.value[0]?.id ?? "";
  }
};

const onRemoveAdLayout = (adLayoutId: string) => {
  currentData.value.adConfigurations = currentData.value.adConfigurations.filter(
    (x) => x.configuration.id !== adLayoutId,
  );
};

const setAllocationEvenly = () => {
  const countConfigurations = currentData.value.adConfigurations.length;
  const allocation = Math.round(100 / countConfigurations);
  const modulo = 100 % countConfigurations;

  currentData.value.adConfigurations = currentData.value.adConfigurations.map((it, index) => ({
    ...it,
    allocation: index === 0 ? allocation + modulo : allocation,
  }));
};

const adjustAllocation = (layoutId: string) => {
  const configuration = currentData.value.adConfigurations.find(({ configuration: { id } }) => id === layoutId);
  const adjustment = Math.max(0, 100 - allocationTotal.value + (configuration?.allocation ?? 0));

  currentData.value.adConfigurations = currentData.value.adConfigurations.map((it) => ({
    ...it,
    allocation: it.configuration.id === layoutId ? adjustment : it.allocation,
  }));
};

const fixButtonItems = computed(() => [
  {
    label: "Split evenly",
    command: setAllocationEvenly,
  },
  { separator: true },
  ...currentData.value.adConfigurations.map(({ configuration: { id, name } }) => ({
    label: `Adjust ${name} (${id})`,
    command: () => {
      adjustAllocation(id);
    },
  })),
]);

const pinnedConfiguration = computed(() =>
  findExistingPinnedConfiguration(
    props.pinnedConfigurations,
    currentData.value.format,
    currentData.value.placement,
    currentData.value.platform,
    currentData.value.adConfigurationVersion,
  ),
);
</script>

<template>
  <div class="m-3 mt-0 mb-5">
    <div class="field">
      <label for="name">Name</label>
      <InputText id="name" v-model="currentData.name" class="w-full" />
    </div>
    <div class="field">
      <label for="description">Description</label>
      <InputText id="description" v-model="currentData.description" class="w-full" />
    </div>
    <div class="formgrid grid">
      <div class="col-3">
        <p>Platform</p>
        <PlatformSelectButton v-model="currentData.platform" />
      </div>
      <div class="col-3">
        <p>Placement</p>
        <PlacementSelectButton v-model="currentData.placement" />
      </div>
      <div class="col-3">
        <p>Format</p>
        <FormatSelect v-model="currentData.format" class="w-full" />
      </div>
      <div class="col-2 col-offset-1">
        <p>Schema Version</p>
        <Dropdown
          v-model="currentData.adConfigurationVersion"
          :options="schemaValues.sort().reverse()"
          placeholder="Select version"
          class="w-full"
          @change="onChangeSchemaVersion"
        />
      </div>
    </div>
  </div>
  <Fieldset legend="Allocations" class="mb-3">
    <div class="flex gap-3 mb-4">
      <Dropdown
        v-model="selectedAdLayout"
        :disabled="!currentData.adConfigurationVersion || filteredLayouts.length === 0"
        :options="filteredLayouts"
        option-label="name"
        option-value="id"
        placeholder="Select an ad layout"
        class="flex-grow-1"
        filter
      >
        <template #option="slotProps">
          <span
            v-if="slotProps.option.id === pinnedConfiguration?.configuration.id"
            class="mr-2 pi pi-map-marker"
            :style="{ fontSize: '1.2rem' }"
          />
          {{ slotProps.option.name }}
        </template>
      </Dropdown>
      <Button icon="pi pi-plus" :disabled="!selectedAdLayout" @click="onAddAdLayout" />
    </div>
    <template v-for="adConfiguration in currentData.adConfigurations" :key="adConfiguration.configuration.id">
      <div class="grid mb-4">
        <div class="col-6 flex align-items-center">
          <span
            v-if="adConfiguration.configuration.id === pinnedConfiguration?.configuration.id"
            class="mr-2 pi pi-map-marker"
            :style="{ fontSize: '1.2rem' }"
          />
          {{ adConfiguration.configuration.name }}
          <i>({{ adConfiguration.configuration.id }})</i>
        </div>
        <div class="col-2">
          <div>
            <InputNumber v-model="adConfiguration.allocation" class="w-full" />
            <Slider v-model="adConfiguration.allocation" />
          </div>
        </div>
        <div class="col-4 text-right">
          <Button
            icon="pi pi-trash"
            severity="danger"
            @click="() => onRemoveAdLayout(adConfiguration.configuration.id)"
          />
        </div>
      </div>
    </template>
    <div class="flex justify-content-between">
      <p>
        Allocation total :
        <strong>{{ allocationTotal }}%</strong>
      </p>
      <template v-if="currentData.adConfigurations.length && allocationTotal !== 100">
        <InlineMessage severity="warn">The total of allocations must be 100%</InlineMessage>
        <SplitButton
          label="Fix this"
          icon="pi pi-wrench"
          severity="warning"
          :model="fixButtonItems"
          @click="setAllocationEvenly"
        />
      </template>
    </div>
  </Fieldset>
  <div class="flex gap-3 justify-content-end mr-1">
    <Button label="Cancel" text @click="onCancelClick" />
    <Button
      label="Save"
      :disabled="!currentData.name || !currentData.adConfigurations.length || allocationTotal !== 100"
      @click="onSaveClick"
    />
  </div>
</template>
