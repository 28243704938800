import type { AdFormatSchema, SchemaSdkVersion } from "@/types/schema-types";

import { getMissionControlApi } from "./helper";

const instance = getMissionControlApi("api/ad-layouts/schemas/");

export const getSchemas = (): Promise<AdFormatSchema[]> => instance.get<AdFormatSchema[]>("").then((x) => x.data);

export const getSchemasSemver = (): Promise<SchemaSdkVersion> =>
  instance.get<SchemaSdkVersion>("semver").then((x) => x.data);
