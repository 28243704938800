import type { RouteRecordRaw } from "vue-router";

import ForbiddenPage from "@/pages/authentication/ForbiddenPage.vue";
import LoginPage from "@/pages/authentication/LoginPage.vue";
import LogoutPage from "@/pages/authentication/LogoutPage.vue";

import links from "./links";

export const authRoutes: RouteRecordRaw[] = [
  {
    path: `/${links.login}`,
    name: links.login,
    component: LoginPage,
    props: true,
    meta: {
      noAuthRequired: true,
      noAuthOnly: true,
    },
  },
  {
    path: `/${links.logout}`,
    name: links.logout,
    component: LogoutPage,
    props: true,
  },
  {
    path: `/${links.forbidden}`,
    name: links.forbidden,
    component: ForbiddenPage,
    props: true,
    meta: {
      noAuthRequired: true,
    },
  },
];
