import type { AbTest } from "@/types/ab-test-types";
import type { Format, Placement, Platform } from "@/types/common-types";
import { formats, placements, platforms } from "@/types/common-types";
import { AdLayout } from "@/types/layout-types";
import type { PinnedConfiguration, PinnedConfigurationPayload } from "@/types/pinned-types";

import { getMissionControlApi } from "./helper";

const instance = getMissionControlApi("api/ad-layouts/pinned");

export const transformPinnedConfiguration = (pinnedConfiguration: PinnedConfiguration): PinnedConfigurationPayload => ({
  id: pinnedConfiguration.id,
  placement: pinnedConfiguration.placement,
  format: pinnedConfiguration.format,
  platform: pinnedConfiguration.platform,
  version: pinnedConfiguration.version,
  configuration: pinnedConfiguration.configuration.id,
});

export const findExistingPinnedConfiguration = (
  pinnedConfigurations: PinnedConfiguration[],
  format: Format,
  placement: Placement,
  platform: Platform,
  version: string,
  id?: string,
): PinnedConfiguration | undefined =>
  pinnedConfigurations.find(
    (x) =>
      x.format === format &&
      x.placement === placement &&
      x.platform === platform &&
      x.version === version &&
      (!id || x.id !== id),
  );

export const getPinnedConfigurations = (): Promise<PinnedConfiguration[]> =>
  instance.get<PinnedConfiguration[]>("").then((x) => x.data);

export const createPinnedConfiguration = (
  pinnedConfiguration: PinnedConfigurationPayload,
): Promise<PinnedConfiguration> =>
  instance.post<PinnedConfiguration>("", { ...pinnedConfiguration, id: null }).then((x) => x.data);

export const updatePinnedConfiguration = (
  pinnedConfiguration: PinnedConfigurationPayload,
): Promise<PinnedConfiguration> =>
  instance.put<PinnedConfiguration>(pinnedConfiguration.id, pinnedConfiguration).then((x) => x.data);

export const deletePinnedConfiguration = (id: string): Promise<void> => instance.delete(id);

export const generatePinnedPayload = (abTest: AbTest, layoutId: string): PinnedConfigurationPayload => ({
  id: abTest.id,
  placement: abTest.placement,
  format: abTest.format,
  platform: abTest.platform,
  version: abTest.adConfigurationVersion,
  configuration: layoutId,
});

export const getDefaultPinnedConfiguration = (defaultAdLayouts: AdLayout[]): PinnedConfiguration[] =>
  platforms.flatMap((platform) =>
    placements.flatMap((placement) =>
      formats.flatMap((format) =>
        defaultAdLayouts.flatMap((defaultAdLayout) => ({
          id: `${platform}-${placement}-${format}-default`,
          placement,
          format,
          platform,
          version: defaultAdLayout.version,
          configuration: {
            ...defaultAdLayout,
            id: `default`,
            name: "Default",
          },
        })),
      ),
    ),
  );
