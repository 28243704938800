<script setup lang="ts">
import Button from "primevue/button";
import { computed, type PropType } from "vue";

import useConfirmDialog from "@/composables/use-confirm-messages";
import useNotification from "@/composables/use-notifications";
import { deletePinnedConfiguration } from "@/services/pinned-services";
import type { PinnedConfiguration } from "@/types/pinned-types";
import { NOTIFICATION_DURATION } from "@/utils/common";

const { confirmDanger } = useConfirmDialog();
const { notifySuccess, notifyError } = useNotification();

const props = defineProps({
  pinned: {
    type: Object as PropType<PinnedConfiguration>,
    default: null,
  },
  openForm: {
    type: Function,
    default: null,
  },
  refetch: {
    type: Function,
    default: null,
  },
});

const buttons = computed(() => [
  {
    label: "Edit",
    icon: "pi pi-file-edit",
    disabled: false,
    severity: "primary",
    command: () => props.openForm(props.pinned.id),
  },
  {
    label: "Delete",
    icon: "pi pi-trash",
    severity: "danger",
    command: async () => {
      if (!(await confirmDanger(`Are you sure you want to delete the pinned configuration ${props.pinned.id}?`))) {
        return;
      }

      try {
        await deletePinnedConfiguration(props.pinned.id);
        notifySuccess({
          detail: `The pinned configuration ${props.pinned.id} was successfully deleted`,
          life: NOTIFICATION_DURATION,
        });
        props.refetch();
      } catch (err) {
        notifyError({ detail: `${err}` });
      }
    },
  },
]);
</script>
<template>
  <div class="flex justify-content-end">
    <div v-for="button in buttons.filter((x) => !x.disabled)" :key="button.label">
      <Button
        v-tooltip.bottom="button.label"
        type="button"
        :icon="button.icon"
        :aria-label="button.label"
        :severity="button.severity || 'primary'"
        text
        rounded
        @click="button.command"
      />
    </div>
  </div>
</template>
