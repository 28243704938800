<script setup lang="ts">
import { useQuery, useQueryClient } from "@tanstack/vue-query";
import { FilterMatchMode } from "primevue/api";
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import InputText from "primevue/inputtext";
import type { MenuItem } from "primevue/menuitem";
import OverlayPanel from "primevue/overlaypanel";
import SplitButton from "primevue/splitbutton";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import { computed, onMounted, ref, type ComputedRef, type Ref } from "vue";

import { LayoutConfigurationTable, LayoutUsage, LayoutsDifferencesTable } from "@/components/business";
import useConfirmDialog from "@/composables/use-confirm-messages";
import useNotification from "@/composables/use-notifications";
import { getAbTests } from "@/services/ab-tests-services";
import { deleteAdLayout, getAdLayouts } from "@/services/ad-layouts-services";
import { getPinnedConfigurations } from "@/services/pinned-services";
import { getSchemas } from "@/services/schemas-services";
import { getAdLayoutEndpoint, getAdLayoutEndpoints, getAdLayoutEndpointsByVersion } from "@/services/test-app-services";
import type { AdLayout } from "@/types/layout-types";
import type { AdFormatSchema } from "@/types/schema-types";
import { type IdUrl } from "@/types/test-app-types";
import { isLayoutUsed } from "@/utils/ad-layout";
import { NOTIFICATION_DURATION } from "@/utils/common";
import { generateQRCode } from "@/utils/qr-code";

import AdLayoutForm from "./components/AdLayoutForm.vue";
import AdLayoutTableMenu from "./components/AdLayoutTableMenu.vue";

useQueryClient();
const layoutVersionLinks: Ref<Map<string, IdUrl>> = ref(new Map());
const layoutLinks: Ref<Map<string, IdUrl>> = ref(new Map());
const qrCodeOverlayPanel = ref();
const expandedRows = ref([]);
const filters = ref();
const { confirmDanger } = useConfirmDialog();
const { notifySuccess } = useNotification();
const refreshLayoutVersionQrCodes = async () => {
  const links = await Promise.all(
    (
      await getAdLayoutEndpointsByVersion()
    ).map(async (it) => ({ ...it, qrCode: await generateQRCode(it.url) } as IdUrl)),
  );

  layoutVersionLinks.value = new Map(links.map((x) => [x.id, x]));
};

const refreshLayoutQrCodes = async () => {
  const links = await Promise.all(
    (await getAdLayoutEndpoints()).map(async (it) => ({ ...it, qrCode: await generateQRCode(it.url) } as IdUrl)),
  );

  layoutLinks.value = new Map(links.map((x) => [x.id, x]));
};

const refreshLayoutQrCode = async (layoutId: string) => {
  const { id, url } = await getAdLayoutEndpoint(layoutId);
  const qrCode = await generateQRCode(url);
  layoutLinks.value.set(id, { id, url, qrCode });
};

onMounted(async () => {
  refreshLayoutVersionQrCodes();
  refreshLayoutQrCodes();
});

const { data: schemasData } = useQuery({
  queryKey: ["schemas"],
  queryFn: getSchemas,
  staleTime: Infinity,
});

const {
  data: adLayouts,
  isLoading,
  refetch,
} = useQuery({
  queryKey: ["adlayouts"],
  queryFn: getAdLayouts,
  staleTime: Infinity,
});

const { data: pinnedConfigurations } = useQuery({
  queryKey: ["pinned"],
  queryFn: getPinnedConfigurations,
  staleTime: Infinity,
});

const { data: abTests } = useQuery({
  queryKey: ["abtests"],
  queryFn: getAbTests,
  staleTime: Infinity,
});

const schemas = computed(() => [...(schemasData.value || [])].sort((a, b) => b.version.localeCompare(a.version)));

const initFilters = () => {
  filters.value = { global: { value: null, matchMode: FilterMatchMode.CONTAINS } };
};
initFilters();

const isFormVisible = ref(false);
const isDiffVisible = ref(false);
const selectedSchema = ref<AdFormatSchema | undefined>();
const selectedTabSchema = ref<AdFormatSchema | undefined>();
const selectedLayout = ref<AdLayout | undefined>();
const selectedLayouts = ref<AdLayout[]>([]);

const lastSchema = computed(() => {
  const [firstElem] = schemas.value || [];
  return firstElem;
});

const formTitle = computed(() =>
  selectedLayout.value?.id
    ? `Edit ad layout: ${selectedLayout.value.name} (${selectedLayout.value.id})`
    : `Create ad layout for schema v${selectedSchema.value?.version}`,
);

const openForm = (schema: AdFormatSchema, layout?: AdLayout) => {
  selectedSchema.value = schema;
  selectedLayout.value = layout;
  isFormVisible.value = true;
};

const closeForm = () => {
  refetch();
  isFormVisible.value = false;
  selectedLayout.value = undefined;
};

const menuItems: ComputedRef<MenuItem[]> = computed(
  () =>
    [...(schemas.value || [])].slice(1).map((x) => ({
      label: `Create layout version ${x.version}`,
      icon: "pi pi-plus",
      command: () => openForm(x),
    })) || [],
);

const deletableLayouts = computed(() =>
  selectedLayouts.value.filter((it) => !isLayoutUsed(it.id, abTests.value || [], pinnedConfigurations.value || [])),
);

const deleteLayouts = async () => {
  const deletableLayoutsCount = deletableLayouts.value.length;
  if (
    await confirmDanger(
      `Are you sure you want to delete ${deletableLayoutsCount} ad layouts? (Used Layouts will not be deleted)`,
    )
  ) {
    Promise.all(deletableLayouts.value.map((it) => deleteAdLayout(it.id))).then(() => {
      notifySuccess({
        detail: `${deletableLayoutsCount} ad layouts successfully deleted`,
        life: NOTIFICATION_DURATION,
      });
      refetch();
    });

    selectedLayouts.value = [];
  }
};

const schemasWithAdLayouts = computed(
  () =>
    schemas.value
      ?.filter((it) => adLayouts.value?.some((x) => x.version === it.version))
      .sort((a, b) => b.version.localeCompare(a.version)) || [],
);
</script>

<template>
  <div class="flex justify-content-between align-items-center">
    <h1>Ad Layouts</h1>
    <SplitButton
      v-if="lastSchema"
      :model="menuItems"
      :label="`Create layout version ${lastSchema.version}`"
      icon="pi pi-plus"
      class="ml-3"
      @click="(_) => openForm(lastSchema)"
    />
  </div>
  <div class="mb-4">
    <Button
      type="button"
      icon="pi pi-qrcode"
      label="Show QR Codes"
      @click="($event) => qrCodeOverlayPanel.toggle($event)"
    />
    <OverlayPanel ref="qrCodeOverlayPanel">
      <TabView :scrollable="true">
        <TabPanel header="SDK">
          <img :src="layoutVersionLinks.get('sdk')?.qrCode || ''" alt="QR Code" />
        </TabPanel>
        <TabPanel v-for="schema in schemasWithAdLayouts" :key="schema.id" :header="`Version: ${schema.version}`">
          <img :src="layoutVersionLinks.get(schema.version)?.qrCode || ''" alt="QR Code" />
        </TabPanel>
      </TabView>
      <div class="flex justify-content-center">
        <Button icon="pi pi-refresh" label="Refresh QR Codes" text class="ml-3" @click="refreshLayoutVersionQrCodes" />
      </div>
    </OverlayPanel>
  </div>
  <TabView
    :scrollable="true"
    @tab-change="
      (event) => {
        selectedTabSchema = schemasWithAdLayouts[event.index];
        selectedLayouts = [];
      }
    "
  >
    <TabPanel v-for="schema in schemasWithAdLayouts" :key="schema.id" :header="`Version: ${schema.version}`">
      <DataTable
        v-model:expandedRows="expandedRows"
        v-model:filters="filters"
        v-model:selection="selectedLayouts"
        :loading="isLoading"
        striped-rows
        :value="adLayouts?.filter((it) => it.version === schema.version)"
        :global-filter-fields="['name', 'id']"
        data-key="id"
      >
        <template #header>
          <div class="flex justify-content-between">
            <div class="flex gap-2 align-items-center">
              <Button
                label="Compare ad layouts"
                :badge="selectedLayouts.length.toString()"
                icon="pi pi-code"
                size="small"
                :disabled="selectedLayouts.length < 2"
                @click="() => (isDiffVisible = true)"
              />
              <Button
                :disabled="deletableLayouts.length === 0"
                size="small"
                label="Delete ad layouts"
                :badge="deletableLayouts.length.toString()"
                icon="pi pi-trash"
                severity="danger"
                outlined
                badge-class="p-badge-danger"
                @click="deleteLayouts"
              />
            </div>
            <div>
              <IconField icon-position="left">
                <InputIcon class="pi pi-search"></InputIcon>
                <InputText v-model="filters['global'].value" placeholder="Search" />
              </IconField>
            </div>
          </div>
        </template>
        <template #empty>
          <div class="flex justify-content-center align-items-center">
            <div class="text-center">
              <h3>No Ad Layout found</h3>
              <p>Change filter to display existing Ad Layout or create a new one.</p>
            </div>
          </div>
        </template>
        <Column selection-mode="multiple" header-style="width: 3rem" />
        <Column expander style="width: 5rem" />
        <Column header="Name" sortable sort-field="name">
          <template #body="slotProps">
            {{ slotProps.data.name }}
            <em>({{ slotProps.data.id }})</em>
            {{ " " }}
            <LayoutUsage
              :id="slotProps.data.id"
              :ab-tests="abTests || []"
              :pinned-configurations="pinnedConfigurations || []"
            />
          </template>
        </Column>
        <Column header="">
          <template #body="slotProps">
            <div class="flex justify-content-end">
              <AdLayoutTableMenu
                :ad-layout="slotProps.data"
                :schema="schema"
                :open-form="openForm"
                :refetch="refetch"
                :last-schema="lastSchema"
                :layout-used="isLayoutUsed(slotProps.data.id, abTests || [], pinnedConfigurations || [])"
              />
            </div>
          </template>
        </Column>
        <template #expansion="slotProps">
          <div class="grid">
            <div class="col-1">
              <img
                class="w-full mt-1"
                :src="layoutLinks.get(slotProps.data.id)?.qrCode || ''"
                alt="qrCode for layout"
              />

              <div class="flex justify-content-center">
                <Button
                  icon="pi pi-refresh"
                  aria-label="Refresh QR Codes"
                  text
                  rounded
                  @click="(_) => refreshLayoutQrCode(slotProps.data.id)"
                />
              </div>
            </div>
            <div class="col-11">
              <LayoutConfigurationTable :ad-layout="slotProps.data" :schema="schema" />
            </div>
          </div>
        </template>
      </DataTable>
    </TabPanel>
  </TabView>
  <Dialog v-model:visible="isFormVisible" :header="formTitle" maximizable modal :style="{ width: '75vw' }">
    <AdLayoutForm :schema="selectedSchema" :edit-layout="selectedLayout" :close-form="closeForm" />
  </Dialog>
  <Dialog v-model:visible="isDiffVisible" header="Layouts differences" maximizable modal style="width: 75vw">
    <LayoutsDifferencesTable :ad-layouts="selectedLayouts" :schema="selectedTabSchema || lastSchema" />
    <div class="mt-4 flex justify-content-end">
      <Button label="Close" @click="() => (isDiffVisible = false)" />
    </div>
  </Dialog>
</template>
