<script setup lang="ts">
import { ComputedRef, PropType, StyleValue, computed } from "vue";

const props = defineProps({
  clickableRatio: {
    type: String,
    default: "PERCENT_100",
  },
  clickOnce: {
    type: Boolean,
    default: false,
  },
  clickableRatioColor: {
    type: String,
    default: "#FF000050",
  },
  buttonSize: {
    type: String,
    default: "20px",
  },
  action: {
    type: Function as PropType<() => void>,
    default: null,
  },
});

const clickableRatioFirstClick = computed(() => props.clickableRatio.split("_")[1] ?? "100");
const clickableRatioSecondClick = computed(() => props.clickableRatio.split("_")[3]);

const currentRatio = computed(() =>
  Number.parseInt(
    (props.clickOnce ? clickableRatioSecondClick.value : clickableRatioFirstClick.value) ||
      clickableRatioFirstClick.value,
    10,
  ),
);
const clickableSize = computed(
  () => (Number.parseInt(props.buttonSize.replace("px", ""), 10) * currentRatio.value) / 100,
);
const clickableOffset = computed(() => {
  switch (currentRatio.value) {
    case 100:
      return 0;
    case 85:
      return 1;
    case 75:
      return 2;
    case 50:
      return 4;
    case 40:
      return 6;
    case 25:
      return 8;
    case 10:
      return 10;
    default:
      return 0;
  }
});

const style: ComputedRef<StyleValue> = computed(() => ({
  position: "absolute",
  borderRadius: "50%",
  width: `${clickableSize.value}px`,
  height: `${clickableSize.value}px`,
  background: props.clickableRatioColor,
  top: `${clickableOffset.value}px`,
  left: `${clickableOffset.value}px`,
  zIndex: 10,
}));
</script>

<template>
  <div :style="style" @click="action" @keypress="action"></div>
</template>
