import { VueQueryPlugin } from "@tanstack/vue-query";
import PrimeVue from "primevue/config";
import "primevue/resources/themes/lara-light-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import { createApp } from "vue";

import "./styles/index.scss";
import "./styles/business.scss";
import App from "./App.vue";
import { router } from "./router";

const app = createApp(App);

app.use(router);
app.use(PrimeVue, { ripple: true });
app.use(ToastService);
app.use(ConfirmationService);
app.use(VueQueryPlugin);
app.directive("tooltip", Tooltip);

app.mount("#app");
