<script setup lang="ts">
import Button from "primevue/button";
import { computed, type PropType } from "vue";

import useConfirmDialog from "@/composables/use-confirm-messages";
import useNotification from "@/composables/use-notifications";
import { deleteAbTest, toggleAbTestActivation } from "@/services/ab-tests-services";
import type { AbTest } from "@/types/ab-test-types";

const { confirmDanger } = useConfirmDialog();
const { notifySuccess, notifyError } = useNotification();

const props = defineProps({
  abTest: {
    type: Object as PropType<AbTest>,
    default: null,
  },
  openForm: {
    type: Function,
    default: null,
  },
  refresh: {
    type: Function,
    default: null,
  },
  isPinned: {
    type: Boolean,
    default: false,
  },
});

const buttons = computed(() => [
  {
    label: props.abTest.active ? "Pause" : "Run",
    icon: props.abTest.active ? "pi pi-pause" : "pi pi-play",
    disabled: props.isPinned,
    severity: props.abTest.active ? "warning" : "success",
    command: async () => {
      try {
        await toggleAbTestActivation(props.abTest.id);
        notifySuccess({
          detail: `The AbTest was successfully ${props.abTest.active ? "paused" : "started"}`,
          life: 5000,
        });
        props.refresh();
      } catch (err) {
        notifyError({ detail: `${err}` });
      }
    },
  },
  {
    label: "Edit",
    icon: "pi pi-file-edit",
    command: () => props.openForm(props.abTest.id),
  },
  {
    label: "Delete",
    icon: "pi pi-trash",
    severity: "danger",
    command: async () => {
      const response = await confirmDanger(`Do you want to delete this AbTest: ${props.abTest.name}`);
      if (response) {
        try {
          await deleteAbTest(props.abTest.id);
          notifySuccess({
            detail: `The AbTest was successfully deleted`,
            life: 5000,
          });
          props.refresh();
        } catch (err) {
          notifyError({ detail: `${err}` });
        }
      }
    },
  },
]);
</script>

<template>
  <div class="flex justify-content-end">
    <div v-for="button in buttons.filter((x) => !x.disabled)" :key="button.label">
      <Button
        v-tooltip.bottom="button.label"
        type="button"
        :icon="button.icon"
        :aria-label="button.label"
        :severity="button.severity || 'primary'"
        text
        rounded
        @click="button.command"
      />
    </div>
  </div>
</template>
