import type { AbAllocation, AbAllocationPayload, AbTest } from "@/types/ab-test-types";
import type { Format, Placement, Platform } from "@/types/common-types";
import { AdLayoutDifference } from "@/types/layout-types";
import { getAdLayoutDifferences } from "@/utils/ad-layout";

import { getMissionControlApi } from "./helper";

const instance = getMissionControlApi("api/ad-layouts/ab-tests");

const transformConfiguration = (adConfigurations: AbAllocation[]): AbAllocationPayload[] => {
  let allocationTotal = 0;
  return adConfigurations
    .sort((a, b) => b.allocation - a.allocation)
    .map(({ allocation, configuration: { id } }) => {
      allocationTotal += allocation;
      return { configuration: id, allocation, allocationTotal };
    });
};

export const findExistingActiveAbTest = (
  abtests: AbTest[],
  format: Format,
  placement: Placement,
  platform: Platform,
  version: string,
): AbTest | undefined =>
  abtests.find(
    (x) =>
      x.format === format &&
      x.placement === placement &&
      x.platform === platform &&
      x.adConfigurationVersion === version &&
      x.active,
  );

export const getAdLayoutDifferencesFromAbTest = (abtest: AbTest): AdLayoutDifference[] =>
  getAdLayoutDifferences(abtest.adConfigurations.map((x) => x.configuration));

export const getAbTests = (): Promise<AbTest[]> => instance.get<AbTest[]>("").then((x) => x.data);

export const createAbTest = (abTest: AbTest): Promise<AbTest> => {
  const { adConfigurations } = abTest;
  return instance
    .post("", { ...abTest, id: null, adConfigurations: transformConfiguration(adConfigurations) })
    .then((x) => x.data);
};

export const updateAbTest = (abTest: AbTest): Promise<AbTest> => {
  const { id, adConfigurations } = abTest;
  return instance
    .put(id, { ...abTest, adConfigurations: transformConfiguration(adConfigurations) })
    .then((x) => x.data);
};

export const toggleAbTestActivation = (id: string): Promise<AbTest> => instance.get(`${id}/toggle`);

export const deleteAbTest = (id: string): Promise<boolean> => instance.delete(id).then((_) => true);
