<script setup lang="ts">
import ProgressBar from "primevue/progressbar";
import { PropType, StyleValue, computed } from "vue";

const props = defineProps({
  configuration: {
    type: Object as PropType<Array<{ key: string; value: string }>>,
    default: null,
  },
  currentTime: {
    type: Number,
    default: 10,
  },
  totalTime: {
    type: Number,
    default: 30,
  },
  interval: {
    type: Number,
    default: 1000,
  },
});

const value = computed(() => (props.currentTime / props.totalTime) * 100);
const enabled = computed(() => props.configuration?.find(({ key }) => key === "pbe")?.value ?? "false");
const color = computed(() => props.configuration?.find(({ key }) => key === "pbc")?.value ?? "#FFFFFF");

const containerStyle: StyleValue = {
  position: "absolute",
  bottom: "8px",
  width: "calc(100% - 16px)",
  height: "8px",
  overflow: "hidden",
  left: "8px",
  borderBottomLeftRadius: "10px",
  borderBottomRightRadius: "10px",
};
</script>

<template>
  <div v-if="enabled === 'true' || enabled === '1'" :style="containerStyle">
    <ProgressBar
      :value="value"
      :show-value="false"
      :pt="{
        root: { style: { borderRadius: '0' } },
        value: { style: { backgroundColor: color, transition: `all ${interval}ms linear 0s` } },
      }"
    />
  </div>
</template>
