<script setup lang="ts">
import { useQuery, useQueryClient } from "@tanstack/vue-query";
import { FilterMatchMode } from "primevue/api";
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import InputText from "primevue/inputtext";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import ToggleButton from "primevue/togglebutton";
import { ref, computed } from "vue";

import {
  LayoutConfigurationTable,
  PlacementSelectButton,
  PlatformLabel,
  PlatformSelectButton,
} from "@/components/business";
import FormatSelect from "@/components/business/FormatSelect/FormatSelect.vue";
import { getAbTests } from "@/services/ab-tests-services";
import { generateAdLayoutFromSchema, getAdLayouts } from "@/services/ad-layouts-services";
import { getDefaultPinnedConfiguration, getPinnedConfigurations } from "@/services/pinned-services";
import { getSchemas } from "@/services/schemas-services";
import { type PinnedConfiguration } from "@/types/pinned-types";

import PinnedForm from "./components/PinnedForm.vue";
import PinnedTableMenu from "./components/PinnedTableMenu.vue";

useQueryClient();

const expandedRows = ref([]);
const filters = ref();

const {
  isLoading,
  data: pinnedConfigurations,
  refetch,
} = useQuery({
  queryKey: ["pinned"],
  queryFn: getPinnedConfigurations,
  staleTime: Infinity,
});

const { data: schemas } = useQuery({
  queryKey: ["schemas"],
  queryFn: getSchemas,
  staleTime: Infinity,
});

const { data: adLayouts } = useQuery({
  queryKey: ["adlayouts"],
  queryFn: getAdLayouts,
  staleTime: Infinity,
});

const { data: abTestsData, refetch: refetchAbTests } = useQuery({
  queryKey: ["abtests"],
  queryFn: getAbTests,
  staleTime: Infinity,
});

const initFilters = () => {
  filters.value = { global: { value: null, matchMode: FilterMatchMode.CONTAINS } };
};
initFilters();

const isFormVisible = ref(false);
const isDefaultVisible = ref(true);
const editPinned = ref<PinnedConfiguration | undefined>(undefined);
const platformFilter = ref("ALL");
const placementFilter = ref("ALL");
const formatFilter = ref("ALL");

const formTitle = computed(() =>
  editPinned.value ? `Edit pinned configuration: ${editPinned.value?.id}` : "Add pinned configuration",
);
const openForm = (pinnedId?: string) => {
  editPinned.value = pinnedId ? pinnedConfigurations.value?.find((it) => it.id === pinnedId) : undefined;
  isFormVisible.value = true;
};
const closeForm = () => {
  refetch();
  refetchAbTests();
  isFormVisible.value = false;
  editPinned.value = undefined;
};

const orderedSchemas = computed(() => [...(schemas.value || [])]?.sort((a, b) => b.version.localeCompare(a.version)));

const defaultPinnedConfigurations = computed(() =>
  getDefaultPinnedConfiguration((schemas.value || []).map((it) => generateAdLayoutFromSchema(it)))
    .filter(
      (it) =>
        ![...(pinnedConfigurations.value || [])].some(
          (pinned) =>
            pinned.placement === it.placement &&
            pinned.platform === it.platform &&
            pinned.format === it.format &&
            pinned.version === it.version,
        ),
    )
    .filter((_) => isDefaultVisible.value),
);

const isDefaultPinned = (data: PinnedConfiguration) => data.id?.includes("default");

const rowStyle = (data) => (isDefaultPinned(data) ? { background: "#F5FCF9" } : {});
</script>
<template>
  <div class="flex justify-content-between align-items-center">
    <h1>Pinned configuration</h1>
    <Button label="Create pinned configuration" icon="pi pi-plus" @click="openForm()" />
  </div>
  <TabView scrollable>
    <TabPanel v-for="schema in orderedSchemas" :key="schema.id" :header="`Version: ${schema.version}`">
      <DataTable
        v-model:expanded-rows="expandedRows"
        v-model:filters="filters"
        :row-style="rowStyle"
        :global-filter-fields="['id', 'platform', 'format', 'placement', 'configuration.name']"
        :value="
          [...(pinnedConfigurations || []), ...defaultPinnedConfigurations]
            .filter((it) => it.version === schema.version)
            .filter((it) => platformFilter === 'ALL' || it.platform.toLocaleUpperCase() === platformFilter)
            .filter((it) => placementFilter === 'ALL' || it.placement === placementFilter)
            .filter((it) => formatFilter === 'ALL' || it.format === formatFilter)
        "
        :loading="isLoading"
        data-key="id"
        removable-sort
        sort-mode="multiple"
        :multi-sort-meta="[
          {
            field: 'platform',
            order: 1,
          },
          {
            field: 'placement',
            order: 1,
          },
        ]"
      >
        <template #header>
          <div class="flex justify-content-end">
            <div class="flex gap-2">
              <ToggleButton
                v-model="isDefaultVisible"
                on-icon="pi pi-eye"
                off-icon="pi pi-eye-slash"
                on-label="Default included"
                off-label="Default excluded"
              />
              <FormatSelect v-model="formatFilter" include-all />
              <PlacementSelectButton v-model="placementFilter" include-all :unselectable="false" />
              <PlatformSelectButton v-model="platformFilter" include-all icon-only />
              <IconField icon-position="left">
                <InputIcon class="pi pi-search"></InputIcon>
                <InputText v-model="filters['global'].value" placeholder="Search" />
              </IconField>
            </div>
          </div>
        </template>
        <template #empty>
          <div class="flex justify-content-center align-items-center">
            <div class="text-center">
              <h3>No Pinned Configurations found</h3>
              <p>Change filter to display existing Pinned Configurations.</p>
            </div>
          </div>
        </template>
        <Column expander />
        <Column field="configuration.id" header="Id" sortable sort-field="configuration.id" />
        <Column header="Platform" sortable sort-field="platform">
          <template #body="slotProps">
            <PlatformLabel :platform="slotProps.data.platform" data-key="platform" />
          </template>
        </Column>
        <Column field="placement" header="Placement" sortable sort-field="placement" />
        <Column field="format" header="Format" sortable sort-field="format" />
        <Column field="configuration.name" header="Layout name" sortable sort-field="configuration.name" />
        <Column>
          <template #body="slotProps">
            <PinnedTableMenu
              v-if="!isDefaultPinned(slotProps.data)"
              :pinned="slotProps.data"
              :open-form="openForm"
              :refetch="refetch"
            />
          </template>
        </Column>
        <template #expansion="slotProps">
          <div class="grid">
            <div class="col-3">
              <h3>{{ slotProps.data.configuration.name }}</h3>
            </div>
            <div class="col-9">
              <LayoutConfigurationTable :ad-layout="slotProps.data.configuration" :schemas="schemas" />
            </div>
          </div>
        </template>
      </DataTable>
    </TabPanel>
  </TabView>
  <Dialog v-model:visible="isFormVisible" maximizable modal :header="formTitle" :style="{ width: '75vw' }">
    <PinnedForm
      :edit-pinned="editPinned"
      :pinned-configurations="pinnedConfigurations"
      :layouts="adLayouts"
      :schemas="schemas"
      :abtests="abTestsData"
      :close-modal="closeForm"
    />
  </Dialog>
</template>
