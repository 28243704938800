<script setup lang="ts">
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Button from "primevue/button";
import Dropdown, { type DropdownChangeEvent } from "primevue/dropdown";
import InlineMessage from "primevue/inlinemessage";
import { ref, type PropType, computed } from "vue";

import {
  FormatSelect,
  PlacementSelectButton,
  PlatformSelectButton,
  LayoutConfigurationTable,
} from "@/components/business";
import useConfirmDialog from "@/composables/use-confirm-messages";
import useNotification from "@/composables/use-notifications";
import { findExistingActiveAbTest, toggleAbTestActivation } from "@/services/ab-tests-services";
import {
  createPinnedConfiguration,
  findExistingPinnedConfiguration,
  transformPinnedConfiguration,
  updatePinnedConfiguration,
} from "@/services/pinned-services";
import type { AbTest } from "@/types/ab-test-types";
import type { AdLayout } from "@/types/layout-types";
import type { PinnedConfiguration, PinnedConfigurationPayload } from "@/types/pinned-types";
import type { AdFormatSchema } from "@/types/schema-types";
import { NOTIFICATION_DURATION } from "@/utils/common";

const emptyPinnedConfiguration: PinnedConfigurationPayload = {
  id: "",
  platform: "ANDROID",
  format: "VIDEO_PLAYABLE",
  placement: "FS",
  version: "",
  configuration: "",
};

const { confirmInfo } = useConfirmDialog();
const { notifySuccess, notifyError } = useNotification();

const props = defineProps({
  editPinned: { type: Object as PropType<PinnedConfiguration>, default: undefined },
  schemas: { type: Array as PropType<AdFormatSchema[]>, default: () => [] },
  layouts: { type: Array as PropType<AdLayout[]>, default: () => [] },
  abtests: { type: Array as PropType<AbTest[]>, default: () => [] },
  pinnedConfigurations: { type: Array as PropType<PinnedConfiguration[]>, default: () => [] },
  closeModal: { type: Function, default: null },
});

const currentData = ref(
  props.editPinned ? { ...transformPinnedConfiguration(props.editPinned) } : emptyPinnedConfiguration,
);
const previousVersion = ref(currentData.value.version);

const isFormUnchanged = computed(() =>
  props.editPinned
    ? JSON.stringify(transformPinnedConfiguration(props.editPinned)) === JSON.stringify(currentData.value)
    : false,
);
const schemaValues = computed(() =>
  props.schemas
    .map((x) => x.version)
    .sort()
    .reverse(),
);
const filteredLayouts = computed(() =>
  !currentData.value.version
    ? []
    : props.layouts
        .filter((x) => x.version === currentData.value.version)
        .map(({ id, name }) => ({
          id,
          name,
        })),
);
const selectedAdLayout = computed(() => props.layouts.find((x) => x.id === currentData.value.configuration));
const isExistingPinnedConfiguration = computed(() => {
  const { placement, platform, version, format, id } = currentData.value;
  return !!findExistingPinnedConfiguration(props.pinnedConfigurations, format, placement, platform, version, id);
});

const onChangeSchemaVersion = (event: DropdownChangeEvent) => {
  const { value } = event;
  if (value !== previousVersion.value) {
    currentData.value.configuration = "";
    previousVersion.value = value;
  }
};

const saveForm = async () => {
  try {
    if (!currentData.value.id) {
      await createPinnedConfiguration(currentData.value);
    } else {
      await updatePinnedConfiguration(currentData.value);
    }
    notifySuccess({ detail: "Pinned configuration saved successfully", life: NOTIFICATION_DURATION });
    props.closeModal();
  } catch (error) {
    notifyError({ detail: `${error}` });
  }
};

const onCancelClick = () => {
  currentData.value = emptyPinnedConfiguration;
  props.closeModal();
};

const onSaveClick = async () => {
  const existingAbTest = findExistingActiveAbTest(
    props.abtests,
    currentData.value.format,
    currentData.value.placement,
    currentData.value.platform,
    currentData.value.version,
  );

  if (!existingAbTest) {
    saveForm();
    return;
  }

  if (await confirmInfo("Add this pinned configuration will disable the active A/B test. Do you want to continue?")) {
    await toggleAbTestActivation((existingAbTest as AbTest).id);
    saveForm();
  }
};
</script>

<template>
  <div class="m-3 mt-0 mb-5">
    <div class="formgrid grid">
      <div class="col-3">
        <p>Platform</p>
        <PlatformSelectButton v-model="currentData.platform" />
      </div>
      <div class="col-3">
        <p>Placement</p>
        <PlacementSelectButton v-model="currentData.placement" />
      </div>
      <div class="col-3">
        <p>Format</p>
        <FormatSelect v-model="currentData.format" class="w-full" />
      </div>
      <div class="col-2 col-offset-1">
        <p>Schema Version</p>
        <Dropdown
          v-model="currentData.version"
          :options="schemaValues"
          placeholder="Select version"
          class="w-full"
          @change="onChangeSchemaVersion"
        />
      </div>
    </div>
  </div>
  <div class="m-3 mt-0 mb-5">
    <div class="formgrid grid">
      <div class="col-12 mb-3">
        <p>Configuration</p>
        <Dropdown
          v-model="currentData.configuration"
          :options="filteredLayouts"
          placeholder="Select an ad layout"
          option-label="name"
          option-value="id"
          class="w-full"
          filter
        />
      </div>
      <div class="col-12">
        <Accordion>
          <AccordionTab header="Ad Layout configuration" :disabled="!currentData.configuration">
            <LayoutConfigurationTable :ad-layout="selectedAdLayout" :schemas="props.schemas" />
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  </div>
  <div class="flex gap-3 justify-content-end mr-1">
    <Button label="Cancel" text @click="onCancelClick" />
    <InlineMessage v-if="isExistingPinnedConfiguration" severity="info">
      A pinned configuration already exists for these parameters
    </InlineMessage>
    <Button
      label="Save"
      :disabled="isExistingPinnedConfiguration || !currentData.configuration || isFormUnchanged"
      @click="onSaveClick"
    />
  </div>
</template>
