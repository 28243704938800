export const NOTIFICATION_DURATION = 5000;

export const parseJson = (input: string | object): object => {
  try {
    return typeof input === "string" ? JSON.parse(input) : input;
  } catch (e) {
    return {};
  }
};

export const jsonToString = (input: object | string): string =>
  typeof input === "object" ? JSON.stringify(input) : input;
